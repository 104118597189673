<template>
    <transition name="Fade">
    <div v-if="loadingStatus || messageText" class="gameDialog">
        <div v-if="loadingStatus" class="gameDialog-loading">
            <div class="gameDialog_first"></div>
            <div class="gameDialog_second"></div>
            <div class="gameDialog_three"></div>
        </div>
        <div v-if="messageText" class="gameDialog-words">
            <i :class="[messageType ? 'gameDialog_success' : 'gameDialog_errors']"></i>
            <h3>{{messageText}}</h3>
        </div>
    </div>
    </transition>
</template>

<script>
    export default {
        name: "Loading",
        computed: {
            loadingStatus() {
                return this.$store.state.loadingStatus
            },
            messageText() {
                return this.$store.state.messageText
            },
            messageType() {
                return this.$store.state.messageType
            }
        },
        watch: {
            messageText (state) {
                if (state) {
                    let times = setTimeout(()=>{
                        this.$store.commit('MESSAGE_TEXT','')
                        clearTimeout(times)
                    },2000)

                }
            },
            messageType (state) {
                if (state) {
                    let times = setTimeout(()=>{
                        this.$store.commit('MESSAGE_TYPE',false)
                        clearTimeout(times)
                    },2000)
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .gameDialog{
        .gameDialog-loading{
            position: fixed;
            top: 50%;left: 50%;
            transform: translate(-50%,-50%);
            text-align: center;
            div{
                width: 30px;
                height: 30px;
                margin-right: 30px;
                border-radius: 100%;
                display:inline-block;
                background-color: #FAD8A1;
                -webkit-animation: three 1.4s infinite ease-in-out;
                animation: three 1.4s infinite ease-in-out;
                -webkit-animation-fill-mode: both;
                animation-fill-mode: both;
            }
            .gameDialog_first{
                -webkit-animation-delay: -0.30s;
                animation-delay: -0.30s;
            }
            .gameDialog_second{
                -webkit-animation-delay: -0.15s;
                animation-delay: -0.15s;
            }
        }
        .gameDialog-words {
            position: fixed;
            top: 90px;
            left: 50%;
            transform: translateX(-50%);
            width: 500px;
            height: 40px;
            background: linear-gradient(90deg, rgba(250, 216, 161, 0) 0%, #E3AF6D 43%, #E3AF6D 60%, rgba(250, 216, 161, 0) 100%);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 2056;

            i,h3{margin: 0;padding: 0}
            .gameDialog_errors {
                width: 18px;
                height: 18px;
                background: url("../../assets/images/officialWebsite/error-icon.png") no-repeat center;
                background-size: 18px 18px;
                margin-right: 15px;
            }
            .gameDialog_success {
                width: 18px;
                height: 18px;
                background: url("../../assets/images/officialWebsite/success-icon.png") no-repeat center;
                background-size: 18px 18px;
                margin-right: 15px;
            }
            h3 {
                text-align: center;
                font-size: 16px;
                color: #FFFFFF;
            }
        }
    }

    .Fade-enter,
    .Fade-leave-to {
        opacity: 0;
    }
    .Fade-enter-to,
    .Fade-leave {
        opacity: 1;
    }
    .Fade-leave-active {
        transition: all 1s;
    }

    @-webkit-keyframes three {
        0%, 80%, 100% {-webkit-transform: scale(0.0) }
        40% { -webkit-transform: scale(1.0) }
    }
    @keyframes three {
        0%, 80%, 100% {-webkit-transform: scale(0.0) }
        40% { -webkit-transform: scale(1.0) }
    }
</style>