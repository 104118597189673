<template>
    <div class="recruit">
        <h2><span>{{recruitDate.professionName}}</span><span :style="recruitDate.bgWidth"></span></h2>
        <p>更新时间：{{recruitDate.updateTime}} | 城市：{{recruitDate.city}}</p>
        <h3>岗位职责</h3>
        <p v-for="(item,i) in recruitDate.jobRespons" :key="i">{{item}} </p>
        <h3>任职要求</h3>
        <p v-for="(item,i) in recruitDate.jobRequire" :key="'jobRequire'+i">{{item}} </p>
        <div class="recruit_btn" @click="joinOurs">加入我们</div>
        <span></span>
    </div>
</template>

<script>
    export default {
        name: "Recruit",
        props: {
            recruitDate: {
                type: Object,
                required: true
            },
            floatShow: {
                type: Boolean,
                default: false,
            }
        },
        data () {
            return {
            }
        },
        methods: {
            joinOurs () {
                this.$emit('update', true)
            }
        }
    }
</script>

<style scoped lang="scss">
    /*屏幕大于1200px*/
    @media screen and (min-width: 1200px) {
        .recruit {
            width: 1120px;
        }
    }
    /*屏幕小于1200px大于800px*/
    @media screen and (max-width: 1200px) and (min-width: 800px) {
        .recruit {
            width: 100%;
        }
    }
    /*屏幕小于800px*/
    @media screen and (max-width: 800px) and (min-width: 600px) {
        .recruit {
            width: 100%;
        }
    }
    /*屏幕小于800px大于375px*/
    @media screen and (max-width: 600px) and (min-width: 375px){
        .recruit {
            width: 100%;
        }
    }
    /*屏幕小于375px*/
    @media screen and (max-width: 374px) {
        .recruit {
            width: 100%;
            margin-left: 30px!important;
        }
    }
    .recruit{
        margin-left: 45px;
        font-size: 18px;
        color: #2F333D;
        h2 {
            padding-right: 30px;
            margin: 30px 0;
            height: 33px;
            position: relative;
            span:nth-child(1) {
                border-bottom: none;
                font-weight: bold;
                font-size: 24px;
                line-height: 27px;
                position: absolute;
                z-index: 3
            }
            span:nth-child(2) {
                display: block;
                height: 12px;
                background: rgba(214, 157, 77, 0.5);
                position: absolute;
                left: 0;bottom: 5px;
                z-index: 2
            }
        }
        h3 {
            font-weight: 500;
            font-size: 18px;
            margin: 30px 0
        }
        p {
            width: 80%;
            color: #606062;
            margin: 0;padding: 0;
        }
        p:nth-of-type(1) {
            color: #ADADB0;
            font-size: 16px;
        }
        .recruit_btn {
            width: 160px;
            height: 48px;
            background: #D69D4D;
            font-size: 18px;
            color: #FFFFFF;
            line-height: 48px;
            text-align: center;
            margin: 42px 0 60px 0;
            cursor:pointer;
        }
        span {
            display: block;
            width: 100%;
            margin: 0 auto;
            height: 0;
            border-bottom: 1px solid #E6E6E6;
        }
    }
</style>