import Vue from "vue";
import axios from 'axios';
import router from '@/router';
import store from '@/store'
import QS from 'qs'

const config = {
	baseURL: '/',
	method: 'GET',
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded',
		Accept: 'application/json',
		'app-version': 'v1.0.0',
		'app-id': 'cn.dygame.web',
		'app-key': 'VvmvxZuu1pfPYgRqBUslZW0X-D1tiIQgLtFmZQ9iKIY',
		'channel-id':'t2cn',
		'device': 'browser',
		'os-version': 'unknown',
	},
	params: {},
	timeout: 10000,
	withCredentials: false,
	responseType: 'json',
	maxContentLength: 2000,
	validateStatus: function (status) {
		return status >= 200 && status < 500;
	},
	maxRedirects: 5,
	transformRequest: [data => QS.stringify(data)],
	paramsSerializer: params => QS.stringify(params),
	data: {},
};
let api = axios.create(config);

// http request 拦截器
api.interceptors.request.use(
	config => {
		// 显示loading效果的路由
		switch (config.url) {
			case "/webapi/v2/startCloudGame":
			case "/webapi/v2/daoyu/game/init/params":
			case "/webapi/v1/news/infos":
			case "/webapi/v1/news/categories":
			case "/webapi/v2/game/details":
			case "/webapi/v2/feedback/record":
			case "/webapi/v2/feedback/reply/record":
			case "/webapi/v2/feedback/reply/publish":
			case "/webapi/v2/comment/reply/view":
			case "/webapi/v2/comment/list":
			case "/webapi/v2/comment/delete":
				store.commit('CHANGE_LOADING', true);
				break;
		}

		if (localStorage.getItem('csrf_token')) {
			config.headers['X-CSRF-TOKEN'] = localStorage.getItem('csrf_token');
		}
		config.headers['Accept'] = 'application/json';
		config.headers['app-id'] = 'cn.dygame.web';
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

// http response 拦截器
api.interceptors.response.use(
	response => {
		store.commit('CHANGE_LOADING', false)
		let httpCode = response.status;
		if (httpCode == 200) {
			if (response.data) {
				let res = response.data
				if (res.status == 0) {
					switch (res.code) {
						case 40031: // 未实名认证
							// 跳转实名认证
							setTimeout(function() {
								store.dispatch('showDialog', true);
								router.push({name: 'index'});
							},2000);
							// return response
							break;
						case 40041: // 叨鱼未实名认证
							// 跳转叨鱼实名认证
							setTimeout(function() {
								window.open('https://i.sdo.com');
							},2000);
							break;
						case 40001: // 用户未登录
							window.localStorage.removeItem('user');
							if (router.history.current.name == 'CloudGame') {
								router.replace({
									name:"Login",
									query: {redirect: router.currentRoute.fullPath}
								})
							}
							break;
						case 40011:
						case 40012: // token失效
							window.localStorage.removeItem('csrf_token');
							store.dispatch('logout')
							router.replace({
								name:"Login",
								query: {redirect: router.currentRoute.fullPath}
							})
							break;
						case 4050:
							return response;break;
					}
					// res.code != 40001 ? store.commit('MESSAGE_TEXT', res.errors[0] || res.message) : '';
					if (res.code != 40001) {
						let msg = res.errors[0] || res.message;
						let noModal = ['号码修改失败', '号码修改成功'];
						!noModal.includes(msg) ?  store.commit('MESSAGE_TEXT', msg) : '';
					}
					return response;
				} else { // 正常情况
					return response;
				}
			}
		} else {
			switch (httpCode) {
				case 401:
					store.commit('MESSAGE_TEXT', '登录凭证失效，请重新登录');
					break;
				case 429:
					store.commit('MESSAGE_TEXT', '操作频繁，请稍后再试');
					break;
				case 500:
					store.commit('MESSAGE_TEXT', '服务异常，请稍后再试');
					break;
				default:
					return Promise.reject(error);
			}
		}
	},
	error => {
		//store.commit('MESSAGE_TEXT', '系统未知错误，请稍后再试')
		return Promise.reject(error);
	}
);

export async function httpGet(url, params) {
	try {
		const res = await api.get(url, { params: params });
		return res;
	} catch (error) {
		throw error;
	}
}

export async function httpPost(url, data, config = {}) {
	try {
		const res = await api.post(url, data, config);
		return res;
	} catch (error) {
		throw error;
	}
}

export async function httpDelete(url, params) {
	try {
		const res = await api.delete(url, { params: params });
		return res;
	} catch (error) {
		throw error;
	}
}

Vue.prototype.$axios = axios;

export default api;
