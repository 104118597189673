import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/beta',
            name: 'Beta',
            component: () => import('@/views/index/Index'),
            meta: {
                title: '传奇云游戏 | 内测',
                keepAlive: true,
                requiresAuth: true,
                sdo: false
            }
        },
        {
            path: '/auth/login',
            name: 'Login',
            meta: {
                title: '传奇云游戏 | 登录',
                sdo: true
            },
            component: () => import('@/views/auth/Login-ele')
        },
        {
            path: '/certification',
            name: 'Certification',
            component: () => import('@/views/user/Certification'),
            meta: {
                requiresAuth: true,
                sdo: true
            }
        },
        {
            path: '/cloud/game/:id/',
            name: 'CloudGame',
            meta: {
                title: '传奇云游戏',
                requiresAuth: true,
                sdo: false
            },
            component: () => import('@/views/cloud/CloudGame'),
        },
        {
            path: '/cloud/phonegame/:id/',
            name: 'CloudGame',
            meta: {
                title: '传奇云游戏',
                requiresAuth: true,
                sdo: false
            },
            component: () => import('@/views/cloud/PhoneCloudGame'),
        },
        {
            path: '/sdo/game/:id/',
            name: 'Sdo',
            component: () => import('@/views/cloud/CloudGame'),
            meta: {
                title: '传奇云游戏',
                sdo: true
            }
        },
        {
            path: '/sdo/phonegame/:id/',
            name: 'Sdo',
            component: () => import('@/views/cloud/PhoneCloudGame'),
            meta: {
                title: '传奇云游戏',
                sdo: true
            }
        },
        {
            path: '/partner/game/:id/',
            name: 'Partner',
            component: () => import('@/views/cloud/CloudGame'),
            meta: {
                title: '传奇云游戏',
                sdo: true
            }
        },
        {
            path: '/partner/phonegame/:id/',
            name: 'Partner',
            component: () => import('@/views/cloud/PhoneCloudGame'),
            meta: {
                title: '传奇云游戏',
                sdo: true
            }
        },
        {
            path: '/joinGame/:password/:token',
            name: 'JoinGame',
            component: () => import('@/views/cloud/UbJoinGame'),
            meta: {
                title: '传奇云游戏',
                sdo: true
            }
        },
        {
            path: '/trial/game/:id/',
            name: 'Trial',
            component: () => import('@/views/cloud/CloudGame'),
            meta: {
                title: '传奇云游戏',
                sdo: true
            },
            props: {name: 'ws_token'}
        },
        {//todo 第三方试玩测试路由
            path: '/try/game',
            name: 'try',
            component: () => import('@/views/test/TryGame'),
            meta: {
                title: '传奇云游戏',
                sdo: false
            }
        },



        /**
         * 官网的路由
         */
        {
            path: '/',
            redirect: '/index'
        },
        {
            path: '/index',
            name: 'index',
            component: () => import('@/views/officialWebsite/index/Index'),
            meta: {
                title: '传奇云游戏 | 让经典游戏再续传奇',
                sdo: true
            }
        },
        {
            path: '/recruitInfo',
            name: 'RecruitInfo',
            component: () => import('@/views/officialWebsite/recruitInfo/RecruitInfo'),
            meta: {
                title: '传奇云游戏 | 加入我们',
                sdo: true
            }
        },
        {
            path: '/newsInfo',
            name: 'NewsInfo',
            component: () => import('@/views/officialWebsite/news/NewsInfo'),
            meta: {
                title: '传奇云游戏 | 新闻资讯',
                sdo: true
            }
        },
        {
            path: '/newsDetail/:news_id',
            name: 'NewsDetail',
            component: () => import('@/views/officialWebsite/news/NewsDetail'),
            meta: {
                title: '传奇云游戏 | 资讯详情',
                sdo: true
            }
        },
        {
            path: '/game/:game_id',
            name: 'GameDetail',
            component: () => import('@/views/officialWebsite/game/GameDetail'),
            meta: {
                title: '传奇云游戏 | 游戏详情',
                sdo: true
            }
        },
        {
            path: '/question',
            name: 'Question',
            component: () => import('@/views/officialWebsite/question/Question'),
            meta: {
                title: '传奇云游戏 | 常见问题',
                sdo: true
            }
        },
        {
            path: '/auth/agreement',
            name: 'Agreement',
            meta: {
                title: '传奇云游戏 | 用户协议',
                sdo: true
            },
            component: () => import('@/views/auth/Agreement')
        },
        {
            path: '/usercenter',
            name: 'UserCenter',
            component: () => import('@/views/user/UserCenter'),
            meta: {
                title: '传奇云游戏 | 个人中心',
                requiresAuth: true,
                sdo: true
            }
        },
        {
            path: '/nosupport',
            name: 'NoSupport',
            component: () => import('@/views/officialWebsite/other/NoSupport')
        },
        {
            path: '/table',
            name: 'Table',
            component: () => import('@/views/test/Table')
        }
    ]
})
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
