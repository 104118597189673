<template>
    <el-dialog title="意见反馈"  :visible.sync="feedDialog" width="686px"  center :before-close="onClose" :modal="false">
        <el-form label-width="80px">
            <!-- form内容 Start-->
            <div class="feed-back-content">
                <div>
                    <label>SID:</label><p>{{session_id}}</p>
                </div>
                <div>
                    <label>反馈类型:</label>
                    <div>
                        <el-radio v-for="(item,index) in feedType"
                        :key="index"
                        v-model="feedForm.type_id"
                        :label="item.id"
                         >{{item.name}}</el-radio>
                    </div>
                </div>
                <div>
                    <label>反馈分类:</label>
                    <el-select v-if="feedForm.type_id==13" v-model="suggestClass_id" :popper-append-to-body="false" clearable placeholder="请选择">
                        <el-option
                        v-for="item in suggestFeedClassify"
                        :key="item.id"
                        :label="item.dict_detail"
                        :value="item.id">
                        </el-option>
                    </el-select>
                    <el-select v-else v-model="questionClass_id" :popper-append-to-body="false" clearable placeholder="请选择">
                        <el-option
                                v-for="item in questionFeedClassify"
                                :key="item.id"
                                :label="item.dict_detail"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div>
                    <label>反馈内容:</label>
                    <textarea v-model="feedForm.feedback_content" placeholder="请输入反馈内容"></textarea>
                </div>
                <div>
                    <label></label>
                    <el-checkbox v-model="agreeStatus">同意工作人员电话回访，回访时间：工作日9:00-18:00</el-checkbox>
                </div>
                <div>
                    <label></label><p>BUG反馈&游戏体验交流Q群：230442940</p>
                </div>
                <div>
                    <label></label><a  href="/#/question/" target="_blank">常见问题 ></a>
                </div>
            </div>
            <!-- End -->
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align:center;">
            <button class="el-button--warning" type="primary" @click="onSubmit">提交</button>
        </div>
    </el-dialog>
</template>
<script>


export default {
    name: "FeedForm",
    props:{
      type:Boolean,
      feedDialog:false
    },
    data() {
        return {
            agreeStatus: false,
            feedForm:{
                session_id: '',
                type_id: 13,                    //类型
                class_id: '',                   //分类
                feedback_content: '',           //内容
                agree_phone_callback: 0,        //回访状态
            },
            feedType:[                          //类型储存
                {id:12,name:'建议'},
                {id:13,name:'问题'}],
            suggestFeedClassify:[],
            questionFeedClassify:[],
            suggestClass_id:10,
            questionClass_id:11,
        };
    },
    computed:{
        session_id(){
            return this.feedForm.session_id = this.$store.state.game.session_id || '';
        }
    },
    mounted(){},
    watch:{
        //   获取分类数据
        feedDialog(state){
            if(state){
                if (this.suggestFeedClassify.length==0){
                    this.$API.getFeedBackClassify()
                        .then((response) => {
                            let res = response.data;
                            if(res.status == 1){
                                // 默认值渲染
                                this.suggestFeedClassify = res.data;
                                this.questionFeedClassify = res.data;
                                this.feedForm.class_id = res.data[8].id;
                            }
                        });
                }
            }
        },
        'feedForm.type_id': {
            handler(state) {
                state = 13 ? this.feedForm.class_id = this.suggestClass_id : this.feedForm.class_id = this.questionClass_id
            },
            deep: true
        }
    },
    methods:{
        reload(){
            this.feedForm = {
                session_id: '',
                type_id: 13,
                agree_phone_callback: 0,
                feedback_content: '',
                class_id : 10,
            }
            this.agreeStatus = false
        },
        onSubmit() {
            // 意见反馈提交
            if(!!this.feedForm.feedback_content){
                this.agreeStatus ? this.feedForm.agree_phone_callback = 1 : '';
                this.$API.submitFeedBack(this.feedForm)
                    .then((response)=>{
                        let res = response.data;
                            if(res.status == 1){
                                // 成功
                                this.$emit('controlDialog', false);
                                this.$store.commit('MESSAGE_TYPE', true);
                                this.$store.commit('MESSAGE_TEXT', '提交反馈成功！');
                                this.reload();
                            }
                    });
            }else{
                this.$store.commit('MESSAGE_TEXT', '请输入反馈内容！');
            }
        },
        onClose(){
            this.$emit('controlDialog', false);
        },
  }

};
</script>

<style lang="scss" scoped>
    .feed-back-content{
        width: 574px;
        margin: 0 auto;
        border-top: 1px solid #606062;
        >div{
            width: 100%;
            margin-top: 20px;
            color: #E6E6E6;
            >label:nth-child(1){
                width: 70px;
                margin-right: 32px;
                float: left;
                color: #ADADB0;
                text-align: right;
            }
        }
        >:nth-child(1){
            // SID
           height: 20px;
        }
        >:nth-child(2){
            // 反馈类型
            >div{
                width: 420px;
                display: flex;
                justify-content: flex-start;
            }
            /deep/ .el-radio{
                .el-radio__input.is-checked .el-radio__inner{
                    border-color: #606062;
                    background: linear-gradient(154deg, #FAD8A1 0%, #E3AF6D 100%);
                }
                .el-radio__inner::after{
                    width: 0px;
                    height: 0px;
                }
                .el-radio__inner:hover{
                    border-color: #606062;
                }
                .el-radio__input+.el-radio__label{
                    font-size: 14px;
                    color: #FFFFFF;
                }

            }
        }
        >:nth-child(3){
        // 反馈分类
        // 下拉样式更改
        /deep/  .el-select{
                    width: 420px;
                    color: #FFFFFF;
                    position: relative;
                    .el-input__inner{
                        border-radius: 4px 4px 0px 0px;
                        background: rgba(0, 0, 0, 0.2);
                        color: #FFFFFF;
                        border:1px solid #606062;
                    }
                    .el-popper[x-placement^=bottom]{
                        margin-top: 0 !important;
                        left: 0px !important;
                        top: 40px !important;
                    }
                    .el-popper[x-placement^=top]{
                        margin-bottom: 0 !important;
}
                    .el-popper[x-placement^=bottom],
                    .el-popper[x-placement^=top]{
                        position: absolute !important;

                        border-radius: 0px 0px 4px 4px;
                        background-color: #262931;
                        border: 1px solid #606062;
                        .el-select-dropdown__wrap{
                            .el-select-dropdown__item{
                                color: #FFFFFF;
                            }
                            .el-select-dropdown__item:hover{
                                background-color: #606062;
                            }
                            .el-select-dropdown__item.selected{
                                color: #FAD8A1 ;
                            }
                        }
                        .el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
                            background-color: #606062;
                        }
                        .popper__arrow{
                            display: none;
                        }
                    }
                }
        }
        >:nth-child(4){
            // 反馈内容
            height: 120px;
            text-align: top;
            textarea{
                width: 420px;
                height: 120px;
                resize: none;
                font-size: 14px;
                border-radius: 4px;
                padding: 10px 16px;
                background: rgba(0, 0, 0, 0.2);
                color: #FFFFFF;
                border: 1px solid #606062;
            }
        }
        >:nth-child(5){
            // 电话回访
            // checkbox样式修改
            /deep/ .el-checkbox{
                .el-checkbox__label{
                    color: #E6E6E6;
                }
                .el-checkbox__input.is-checked .el-checkbox__inner,
                .el-checkbox__input.is-indeterminate .el-checkbox__inner{
                    background: linear-gradient(154deg, #FAD8A1 0%, #E3AF6D 100%);
                    border-radius: 6px;
                }
                .el-checkbox__inner{
                    border: #2F333D;
                    border-radius: 6px;
                }
                .el-checkbox__inner::after{
                    display: none;
                }
            }
        }
        >:nth-child(6){
            margin-top: 0;
            margin-bottom: 0px;
            p{
                padding-left: 125px;
                margin-bottom: 10px;
            }
        }
        >:nth-child(7){
            margin-top: 0;
            a{
                color: #D5A561;
                font-size: 14px;
                padding-left: 24px;
            }
        }
    }
     // 提交按钮
    .el-button--warning{
        width: 108px;
        height: 36px;
        font-size: 14px;
        line-height: 0px;
        border-radius: 4px;
        background: linear-gradient(154deg, #FAD8A1 0%, #E3AF6D 100%);
        box-shadow: 0px 0px 5px 0px rgba(253, 196, 114, 0.6);
        outline: none;
        color: #351B08;
    }
    // element-ui dialog样式
    /deep/ .el-dialog--center{
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 0 !important;
        border-radius: 8px;
        background-color: #2F333D;
    }
    /deep/ .el-dialog__title{
        color: #E6E6E6;
    }

</style>
