<template>
<div id="wrap">
    <TheHeader v-if="$route.meta.keepAlive" />
    <div ref="mainContainer" id="main-container" class="container-fluid main-container">
        <router-view></router-view>
    </div>
    <Loading></Loading>
    <TheFooter v-if="$route.meta.keepAlive" />
</div>
</template>

<script>
import commonRequest from '@/utils/common'

export default {
    name: 'App',
    created() {
        commonRequest.init_csrf_token(this);
        let fullUrl = window.location.href;
        if (fullUrl.indexOf("/usercenter") < 0) {
            // 避免个人中心页面刷新时获取两次用户信息
            commonRequest.request_user_info();
        }

        //刷新页面时,如果用户登录则去获取用户收到的消息
        if (this.$store.state.login.auth) {
            commonRequest.get_user_msg_list();
        }
        localStorage.removeItem('game_info');
    },
    methods: {}
}
</script>

<style lang="scss">
@import "./styles/main";
@import "./styles/common";
</style>
