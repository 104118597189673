<template>
<div :class="gameError ? 'dialogTips' : 'gameDialog'" :id="mobileGameRotateContainer ? 'mobileGameRotateContainer' : '' " v-if="dialogStatus || gameError">
    <div class="gameDialog-con" v-if="(game_areas && !selectedAreaId && gameState !== 'game-closed')
              || (gameState==='waiting' && queueRank >= 0)
              || (gameIsReady && delaySuccessNum > 0)">
        <div class="gameDialog_top"></div>
        <div class="gameDialog_mask">
            <p>{{selectGameName}}</p>
        </div>
        <!--  游戏选区弹窗  -->
        <div class="gameDialog_bottom" v-if="game_areas && !selectedAreaId && gameState !== 'game-closed'">
<!--            <section class="selectArea_title">请选择游戏区</section>-->
            <section class="selectArea_btnGroups">
                <section class="selectArea_btn" v-for="ga in game_areas" :key="ga.game_area_id" @click="selectGameArea(ga.game_area_id)">{{ga.game_area_name}}</section>
            </section>
        </div>
        <!--  游戏排队弹窗  -->
        <div class="gameDialog_bottom" v-if="gameState==='waiting' && queueRank >= 0">
            <section class="gameQueue_wait">
                <p><span>第</span><span>{{queueMessage}}</span><span>位</span></p>
                <div class="gameQueue_wait_copy">
                    <p :class="promptMessage[0]"><span>您已等待 <span>{{delayNum}}</span> 秒</span></p>
                    <p :class="promptMessage[1]">{{promptMessageText[promptMessageControl]}}</p>
                </div>
                <section class="gameQueue_turn"></section>
                <section class="gameQueue_turn1"></section>
                <section class="gameQueue_point"></section>
            </section>
            <section class="gameQueue_btn" @click="quiteQueue">退出排队</section>
        </div>
        <!--  排队成功弹窗  -->
        <div class="gameDialog_bottom" v-if="gameIsReady && delaySuccessNum > 0">
            <section class="queueSuccess_title">排队成功，请进入游戏</section>
            <section class="queueSuccess_icon"></section>
            <section class="queueSuccess_group">
                <section class="queueSuccess_giveUp" @click="()=>{cancelQueue=true}">放弃({{delaySuccessNum}}s)</section>
                <section class="queueSuccess_getInto" @click="playing_game">进入游戏</section>
            </section>
        </div>
    </div>
    <!--  小弹窗  -->
    <div class="gameDialog-min" v-if="(gameState==='waiting-exit')
              || (cancelQueue && gameIsReady)
              || (gameState==='need-to-confirm')
              || (gameState==='playing-conflict')">
        <div class="gameDialog_minBox">
            <!--<i @click="()=>{$emit('changeDialogStatus',false)}"></i>-->
            <!--  取消排队弹窗  -->
            <section class="gameDialog_cancelQueue" v-if="cancelQueue && delaySuccessNum != 0">
                <h3>确定要取消排队吗</h3>
                <p>取消后再次进入游戏需重新排队</p>
                <div class="gameDialog_minGroup">
                    <section @click="()=>{cancelQueue=false}">再等等</section>
                    <section @click="stop_game">确定取消</section>
                </div>
            </section>
            <!--  退出游戏弹窗  -->
            <section class="gameDialog_cancelQueue" v-if="gameState==='waiting-exit'">
                <h3>是否退出游戏</h3>
                <p>退出后再次进入游戏将重新排队</p>
                <div class="gameDialog_minGroup">
                    <section @click="continue_game">取消</section>
                    <section @click="stop_game(true)">确定</section>
                </div>
            </section>
            <!--  切换游戏弹窗  -->
            <section class="gameDialog_cancelQueue" v-if="gameState==='need-to-confirm'">
                <h3>是否切换游戏</h3>
                <p class="switchGame">您正在<span> {{prevSelectGameName}} </span>游戏中排队，是否切换到<span> {{selectGameName}} </span>游戏重新排队</p>
                <div class="gameDialog_minGroup">
                    <section @click="continueQueue">取消</section>
                    <section @click="reQueue">确定</section>
                </div>
            </section>
            <!--  存在未断开的游戏弹窗  -->
            <section class="gameDialog_cancelQueue" v-if="gameState==='playing-conflict'">
                <h3>是否继续操作</h3>
                <p>点击继续将导致已建立的游戏链接断开</p>
                <div class="gameDialog_minGroup">
                    <section @click="quiteQueue">取消</section>
                    <section @click="stop_other_game">继续</section>
                </div>
            </section>
        </div>
    </div>
    <!--  文字提示弹窗  -->
    <div class="gameDialog-words" v-if=" gameState === 'game-closed' ">
        <!--  游戏已结束弹窗  -->
        <h3>游戏已结束-{{gameError}}</h3>
    </div>
    <!-- 排队前强制下线上一个连接的提示框 -->
    <div class="gameDialog-words" v-if="gameState==='force-close-pre-connection'">
        <h3>{{gamePromptMsg}}</h3>
    </div>
</div>
</template>

<script>
import LaunchGameUtil from "@/components/cloudGameLauncher/launchGameUtil"
import localGameState from '@/utils/localGameState'
export default {
    name: "NewCloudGameLauncher",
    props: {
        dialogStatus: {
            type: Boolean,
            default: false
        },
        mobileGameRotateContainer: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            delayNum: 0, //排队耗时
            timer: null, // 排队耗时的计时器
            delaySuccessNum: 300, // 排队成功后计时
            successTimer: null, // 排队成功后的计时器
            cancelQueue: false, // 取消排队弹窗
            nsConn: '',
            queueRank: -1, // 排队人数
            gameAreas: null,
            selectedGameId: "",
            selectedAreaId: "",
            wsToken: "",
            wsServer: "",
            selectedGame: null,
            prevSelectGameName: "", // 当前队列中游戏名称
            prevSelectGameId: "", // 当前队列中游戏ID
            launchType: 'launchGame',
            promptMessage: [], //排队副文本提示信息开关
            interval: 15, //排队副文本提示信息间隔的秒数
            promptMessageControl: 0, //排队副文本提示信息内容控制
            promptMessageText: ['正在全力分配资源', '精彩即将呈现', '当前人数较多，请稍后'] //排队副文本提示信息内容
        }
    },
    created() {
        this.$store.commit('GAME_AREAS', null);
    },
    mounted() {
        this.$eventBus.$on("CancelQueue", (game) => {
            this.quiteQueue();
        });
        window.playGameImmediately = this.playing_game;
        this.$store.commit('Launcher_State', 'minimized');
    },
    computed: {
        gameState() {
            let gameState = localGameState.getGameState();
            if (gameState == 'ready') {
                clearInterval(this.timer);
            }
            return gameState;
        },
        gameError() {
            return localGameState.getGameError();
        },
        gamePromptMsg() {
            return localGameState.gamePromptMsg();
        },
        gameIsReady() {
            return localGameState.getGameState() == 'ready';
        },
        game_areas() {
            return this.$store.state.game.game_areas || '';
        },
        launcherState() {
            return this.$store.state.game.launcher_state;
        },
        selectGameName() {
            return (this.selectedGame) ? this.selectedGame.game_name : '';
        },
        playingGame() {
            return localGameState.getPlayingGame();
        },
        queueMessage() {
            let num = this.queueRank;
            let size = 3;
            if (num < Math.pow(10, size)) { //如果num本身位数小于等于size位则补0
                let _str = Array(size + 1).join('0') + num;
                return _str.slice(_str.length - size);
            } else {
                return '---';
            }
        }
    },
    watch: {
        delayNum: function (state) {
            let interval = this.interval;
            let Surplus = state % interval; //取余
            if (state >= 30) {
                if (Surplus == 0) {
                    // 通过class控制动画展示
                    this.promptMessage = ['runtime_top', 'runtime_down'];
                } else if ((Surplus - 2) == 0) {
                    this.promptMessage = ['runtime_start_top', 'runtime_start_down'];
                } else if ((Surplus - (interval - 4)) == 0) { //间隔将要展示的内容控制
                    // 人数超过10个时显示人数过多的信息提示
                    this.promptMessageControl == (this.queueRank < 10 ? 1 : 2) ? this.promptMessageControl = 0 : this.promptMessageControl++;
                }
            }
        },
        //根据gameState状态的变化来变化UI
        gameState: function (state) {
            switch (state) {
                case 'need-to-confirm':
                    //读取一下原先选定的游戏信息
                    let _prevGame = localGameState.getPrevSelectedGame();
                    if (_prevGame) {
                        this.prevSelectGameName = _prevGame.game_name;
                        this.prevSelectGameId = _prevGame.game_id;
                    }
                    break;
            }
        },
        launcherState(state) {
            switch (state) {
                case "normal":
                    //TODO 需要检查一下当前游戏的状态，如果是playing状态，不要显示
                    this.$bvModal.show('bv-modal-launcher');
                    break;
                case "minimized":
                    this.$bvModal.hide('bv-modal-launcher');
                    break;
            }
        },
        playingGame(state) {
            this.selectedGame = state;
            if (!state) {
                this.stop_game(true);
            }
        },
        gameIsReady(state) {
            clearInterval(this.successTimer)
            if (state) {
                this.successTimer = setInterval(()=>{
                    this.delaySuccessNum > 0 ? this.delaySuccessNum-- : (clearInterval(this.successTimer),localGameState.stopGame('超时未进入游戏'))
                },1000)
            }
        }
    },
    methods: {
        Timer() {
            this.timer = setInterval(() => {
                this.delayNum++
            }, 1000)
        },
        requestStartCloudGame(gameId, token, wsServer) {
            if (!gameId && !token) {
                localGameState.stopGame('游戏启动参数错误');
                return;
            }
            //如果有游戏在运行，或者有在排队，需要中止操作
            if (this.selectedGame && localGameState.getGameState() != 'game-closed') {
                this.$store.commit('Launcher_State', 'normal');
                return;
            }
            localGameState.launchGame();
            this.selectedGameId = gameId;
            this.selectedAreaId = "";
            //清除游戏区列表信息
            this.$store.commit('GAME_AREAS', null);
            this.$emit('changeDialogStatus', true)
            this.launchType = 'launchGame';
            if (token) {
                this.wsServer = LaunchGameUtil.wsServer(wsServer, token);
                this.callback_onRequestWsTokenSuccess(this.wsServer)
            } else {
                LaunchGameUtil.requestWSToken(gameId, this.callback_onRequestWsTokenSuccess);
            }
        },

        requestJoinGame(password, token) {
            this.$emit('changeDialogStatus', true)
            this.launchType = 'joinGame';
            this.wsServer = LaunchGameUtil.wsServer('', token, password);
            this.callback_onRequestWsTokenSuccess(this.wsServer)
        },

        callback_onRequestWsTokenSuccess(ws_server, require_area, game) {
            this.wsServer = ws_server;
            LaunchGameUtil.connectWsServer(ws_server, this.callback_onWsConnected, this.callback_onRankChanged, this.callback_onWsError, this.callback_onWsDisConnected);
        },
        callback_onWsConnected(nsConn) {
            this.nsConn = nsConn;
        },
        callback_onWsError(error_code, error_message) {
            // $("#error_msg").html(error_message);
        },
        callback_onWsDisConnected() {
            this.$eventBus.$emit("CloseCloudGame", '强行断开游戏');
        },
        //刷新排名信息
        callback_onRankChanged(rank) {
            if (rank == 0) rank = 1;
            if (rank > 0 && !this.timer) {
                this.Timer();
            }
            this.queueRank = rank;
            this.selectedGame.ranking = rank;
            localGameState.setSelectedGame(this.selectedGame);
            // localGameState.setQueueRanking(queueTimes);
        },

        playing_game() {
            clearInterval(this.successTimer)
            //通知父组件Game.vue 加载游戏
            if (this.launchType == 'launchGame') {
                this.$emit('startCloudGame', this.nsConn);
            } else if (this.launchType == 'joinGame') {
                this.$emit('joinCloudGame', this.nsConn);
            }
            this.$emit('changeDialogStatus', false)
        },

        confirm_exit_game() {
            this.$emit('changeDialogStatus', true)
            LaunchGameUtil.confirmExitGame();
        },

        stop_game(isByUser) {
            LaunchGameUtil.stopGame(isByUser);
            this.$emit('exit_full_screen')
            if (this.launchType == 'launchGame') {
                this.$emit("stopGamePageAnimation")
            }
        },

        stop_other_game() {
            LaunchGameUtil.forceStopOtherGame();
        },

        continue_game() {
            this.$emit('changeDialogStatus', false)
            LaunchGameUtil.continueGame();
        },

        //选择选区
        selectGameArea(game_area_id) {
            this.selectedAreaId = game_area_id;
            LaunchGameUtil.submitGameArea(game_area_id);
        },
        //继续原有排队，需要更改selected game信息
        continueQueue() {
            this.$emit('changeDialogStatus', false)
            this.selectedGame = localGameState.getPrevSelectedGame();
            //当前所选游戏需要更换为
            LaunchGameUtil.continueLastQueue();
        },
        //重新排队
        reQueue() {
            LaunchGameUtil.reQueue();
        },

        quiteQueue() {
            LaunchGameUtil.quiteQueue();
            window.close();
        },

        onCloudGameError(error_msg) {
            localGameState.setGameError(error_msg);
        }
    },
}
</script>

<style lang="scss" scoped>
@media screen and (max-height: 440px) and (min-height: 310px) {
    .gameDialog {
        .gameDialog-con {
            height: 98% !important;

            .gameDialog_bottom {

                .queueSuccess_icon {
                    margin: 10px 0 15px 0 !important;
                }

                .queueSuccess_group {
                    margin-bottom: 10px !important;
                }
            }
            .selectArea_title {
                display: none !important;
            }
            .selectArea_btnGroups {
                margin-bottom: 15px!important;
            }

            .selectArea_title {
                margin-bottom: 10px !important;
            }

            .gameDialog_top {
                /*display: none!important;*/
            }

            .gameQueue_wait {
                margin-top: 92px !important;
            }

            .gameQueue_btn {
                margin: 10px 0 0 0 !important;
            }
        }
    }
}

@media screen and (max-height: 310px) and (min-height: 280px) {
    .gameDialog {
        .gameDialog-con {
            height: 98% !important;

            .gameDialog_bottom {

                .queueSuccess_icon {
                    margin: 10px 0 15px 0 !important;
                }

                .queueSuccess_group {
                    margin-bottom: 10px !important;
                }
            }


            .selectArea_btnGroups {
                height: 190px!important;
                margin-bottom: 10px!important;
                .selectArea_btn {
                    margin-bottom: 15px !important;
                }
            }

            .queueSuccess_title,
            .selectArea_title {
                display: none !important;
            }

            .gameDialog_top {
                /*display: none!important;*/
            }

            .gameQueue_wait {
                margin-top: 92px !important;
            }

            .gameQueue_btn {
                margin: 10px 0 0 0 !important;
            }
        }
    }
}
@media screen and (max-height: 280px) {
    .gameDialog {
        .gameDialog-con {
            height: 98% !important;

            .gameDialog_bottom {

                .queueSuccess_icon {
                    margin: 10px 0 15px 0 !important;
                }

                .queueSuccess_group {
                    margin-bottom: 10px !important;
                }
            }


            .selectArea_btnGroups {
                height: 160px!important;
                margin-bottom: 10px!important;
                .selectArea_btn {
                    margin-bottom: 15px !important;
                }
            }

            .queueSuccess_title,
            .selectArea_title {
                display: none !important;
            }

            .gameDialog_top {
                /*display: none!important;*/
            }

            .gameQueue_wait {
                margin-top: 92px !important;
            }

            .gameQueue_btn {
                margin: 10px 0 0 0 !important;
            }
        }
    }
}

#mobileGameRotateContainer{
    transform:rotate(90deg);
    -ms-transform:rotate(90deg); 	/* IE 9 */
    -moz-transform:rotate(90deg); 	/* Firefox */
    -webkit-transform:rotate(90deg); /* Safari 和 Chrome */
    -o-transform:rotate(deg);
}

.dialogTips {
    position: absolute;
    top: 50%;left: 50%;
    transform: translate(-50%,-50%);
    .gameDialog-words {
        width: 450px;
        min-height: 70px;
        background: #2F333D;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.5);
        border-radius: 8px;
        display: flex;
        justify-content: center;

        h3 {
            margin: 27px 0 25px 20px;
            font-size: 18px;
            line-height: 25px;
            color: #ffffff;
        }
    }
}
.gameDialog {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    align-items: center;

    .gameDialog-con {
        width: 380px;
        height: 440px;
        background: rgba(47, 51, 61, 1);
        box-shadow: 0px 2px 16px 0px #000000;
        border-radius: 8px;
        position: relative;

        .gameDialog_top {
            width: 380px;
            height: 220px;
            border-radius: 8px;
            background-image: url("../../assets/images/game/game-dialog-bg.png");
            background-size: cover;
        }

        .gameDialog_mask {
            width: 380px;
            height: 220px;
            position: absolute;
            top: 0;

            /*background: linear-gradient(rgba(47,51,61,0.39),rgba(47,51,61,1));*/
            i {
                cursor: pointer;
                width: 14px;
                height: 14px;
                background: url("../../assets/images/game/game-dialog-x2.png") no-repeat;
                background-size: cover;
                position: absolute;
                top: 16px;
                right: 16px;
            }

            p {
                width: 348px;
                height: 25px;
                color: #D5A561;
                font-size: 18px;
                line-height: 25px;
                text-align: center;
                background: url("../../assets/images/game/game-dialog-solidbg.png") no-repeat center center;
                position: absolute;
                top: 40px;
                left: 16px;
            }
        }

        .gameDialog_bottom {
            width: 380px;
            height: auto;
            position: absolute;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            .selectArea_title {
                color: #ADADB0;
                font-size: 18px;
                margin-bottom: 32px;
            }
            .selectArea_btnGroups {
                width: 100%;
                height: 220px;
                margin-bottom: 65px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                overflow-y: scroll;
                scrollbar-width: none;
                .selectArea_btn {
                    width: 296px;
                    height: 72px;
                    text-align: center;
                    line-height: 72px;
                    font-size: 18px;
                    font-weight: bolder;
                    letter-spacing: 2px;
                    margin-bottom: 24px;
                    cursor: pointer;
                    background: url("../../assets/images/game/game-dialog-btn2.png") no-repeat;
                    background-size: cover;
                    color: #351B08;
                }
                .selectArea_btn:hover {
                    background: url("../../assets/images/game/game-dialog-btn1.png") no-repeat;
                    background-size: cover;
                    color: #ffffff;
                }
            }
            .selectArea_btnGroups::-webkit-scrollbar {
                display: none;
            }
            .gameQueue_wait {
                margin-top: 12px;
                width: 214px;
                height: 214px;
                background: url("../../assets/images/game/game-dialog-circular.png") no-repeat;
                background-size: cover;
                position: relative;

                p:nth-child(1) {
                    position: absolute;
                    top: 79px;
                    left: 42px;
                    height: 52px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    span {
                        color: #E6E6E6;
                        font-size: 14px;
                    }

                    span:nth-child(2) {
                        color: #FAD8A1;
                        font-size: 44px;
                        letter-spacing: 4px;
                        margin: 0 10px;
                    }
                }

                // 排队上下滚动样式 -Start-
                .gameQueue_wait_copy {
                    height: 20px;
                    width: 100%;
                    position: absolute;
                    top: 148px;
                    left: 0px;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    p {
                        animation-duration: 0.8s;
                        animation-timing-function: ease-in;
                        animation-fill-mode: both;
                        -webkit-animation-duration: 0.8s;
                        -webkit-animation-timing-function: ease-in;
                        -webkit-animation-fill-mode: both;
                        position: absolute;
                        font-size: 12px;
                        color: #ADADB0;
                    }

                    p:nth-child(1) {
                        top: -16px;
                        width: 100%;
                        left: 0;

                        span {
                            color: #ADADB0;
                            font-size: 12px;

                            span {
                                font-weight: 600;
                                color: #FAD8A1;
                            }
                        }
                    }

                    p:nth-child(2) {
                        top: -25px;
                    }
                }

                // -End-
                .gameQueue_turn {
                    width: 100px;
                    height: 100px;
                    background: url("../../assets/images/game/game-dialog-turn1.png") no-repeat;
                    background-size: cover;
                    position: absolute;
                    top: 7px;
                    right: 7px;
                    transform-origin: left bottom;
                    animation: turn 2s linear infinite;
                }

                .gameQueue_turn1 {
                    width: 100px;
                    height: 100px;
                    background: url("../../assets/images/game/game-dialog-turn1.png") no-repeat;
                    background-size: cover;
                    position: absolute;
                    top: 7px;
                    right: 7px;
                    transform-origin: left bottom;
                    transform: rotate(180deg);
                    animation: turn1 2s linear infinite;
                }

                .gameQueue_point {
                    width: 13px;
                    height: 6px;
                    background: url("../../assets/images/game/game-dialog-turn2.png") no-repeat;
                    background-size: cover;
                    position: absolute;
                    top: 104px;
                    left: -13px;
                    transform-origin: 120px center;
                    animation: antonymTurn 5s linear infinite;
                }

                .gameQueue_connecting {
                    position: absolute;
                    top: 90px;
                    left: 50px;
                    font-size: 20px;
                    font-weight: bolder;
                }
            }

            .gameQueue_btn {
                cursor: pointer;
                width: 140px;
                height: 36px;
                background: url("../../assets/images/game/game-dialog-btn4.png") no-repeat;
                background-size: cover;
                color: #FAD8A1;
                font-size: 14px;
                text-align: center;
                letter-spacing: 2px;
                line-height: 36px;
                margin: 37px 0 48px 0;
            }

            .queueSuccess_title {
                color: #FFFFFF;
                font-size: 18px;
            }

            .queueSuccess_icon {
                width: 144px;
                height: 140px;
                background: url("../../assets/images/game/game-dialog-success.png") no-repeat;
                background-size: cover;
                margin: 48px 0 50px 0;
                animation: iconShow 3s ease-in;
            }

            .queueSuccess_group {
                display: flex;
                margin-bottom: 48px;

                .queueSuccess_giveUp {
                    cursor: pointer;
                    width: 140px;
                    height: 36px;
                    background: url("../../assets/images/game/game-dialog-btn4.png") no-repeat;
                    background-size: cover;
                    color: #FAD8A1;
                    font-size: 14px;
                    text-align: center;
                    line-height: 36px;
                    letter-spacing: 2px;
                    margin-right: 24px;
                }

                .queueSuccess_getInto {
                    cursor: pointer;
                    width: 140px;
                    height: 36px;
                    background: url("../../assets/images/game/game-dialog-btn3.png") no-repeat;
                    background-size: cover;
                    color: #351B08;
                    font-size: 14px;
                    text-align: center;
                    letter-spacing: 2px;
                    line-height: 36px;
                }
            }
        }
    }

    .gameDialog-min {
        width: 380px;
        height: 440px;
        background: rgba(0, 0, 0, 0);
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;

        .gameDialog_minBox {
            width: 340px;
            height: 196px;
            background: #2F333D;
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.5);
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            i {
                width: 14px;
                height: 14px;
                background: url("../../assets/images/game/game-dialog-x1.png") no-repeat;
                background-size: cover;
                cursor: pointer;
                position: absolute;
                top: 14px;
                right: 14px;
            }

            .gameDialog_cancelQueue {
                h3 {
                    text-align: center;
                    font-size: 18px;
                    color: #ffffff;
                    margin-top: 32px;
                }

                p {
                    text-align: center;
                    font-size: 14px;
                    font-weight: 200;
                    color: rgba(255, 255, 255, 0.8);
                    margin: 16px 0 35px 0;

                    span {
                        color: #FAD8A1;
                        font-weight: bolder;
                    }
                }

                .switchGame {
                    width: 248px;
                    text-align: left;
                }

                .gameDialog_minGroup {
                    display: flex;
                    margin-bottom: 24px;

                    section {
                        cursor: pointer;
                        width: 108px;
                        height: 36px;
                        text-align: center;
                        font-size: 14px;
                        line-height: 36px;
                        letter-spacing: 2px;
                    }

                    section:nth-child(1) {
                        background: url("../../assets/images/game/game-dialog-btn4.png") no-repeat;
                        background-size: 108px 36px;
                        color: #FAD8A1;
                        margin-right: 32px;
                    }

                    section:nth-child(2) {
                        background: url("../../assets/images/game/game-dialog-btn3.png") no-repeat;
                        background-size: 108px 36px;
                        color: #351B08;
                    }
                }
                .gameDialog_minSure {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 24px;

                    section {
                        cursor: pointer;
                        width: 108px;
                        height: 36px;
                        text-align: center;
                        font-size: 14px;
                        line-height: 36px;
                        letter-spacing: 2px;
                        background: url("../../assets/images/game/game-dialog-btn3.png") no-repeat;
                        background-size: 108px 36px;
                        color: #351B08;
                    }

                }
            }
        }
    }


}

// 动画效果
@keyframes turn {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0);
    }
}

@keyframes turn1 {
    from {
        transform: rotate(540deg);
    }

    to {
        transform: rotate(180deg);
    }
}

@keyframes antonymTurn {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes iconShow {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

// 排队文字60s提示上移效果 -Start-
.runtime_top {
    animation-name: fade_top;
    -webkit-animation-name: fade_top;
}

.runtime_down {
    animation-name: fade_down;
    -webkit-animation-name: fade_down;
}

.runtime_start_top {
    animation-name: start_top;
    -webkit-animation-name: start_top;
}

.runtime_start_down {
    animation-name: start_down;
    -webkit-animation-name: start_down;
}

@keyframes fade_top {
    0% {
        transform: translateY(0px);
        -webkit-transform: translateY(0px);
    }

    100% {
        transform: translateY(20px);
        -webkit-transform: translateY(20px);
    }
}

@keyframes fade_down {
    0% {
        transform: translateY(0px);
        -webkit-transform: translateY(0px);
    }

    100% {
        transform: translateY(25px);
        -webkit-transform: translateY(25px);
    }
}

@keyframes start_top {
    0% {
        transform: translateY(-20px);
        -webkit-transform: translateY(-20px);
    }

    100% {
        transform: translateY(0px);
        -webkit-transform: translateY(0px);
    }
}

@keyframes start_down {
    0% {
        transform: translateY(25px);
        -webkit-transform: translateY(25px);
    }

    100% {
        transform: translateY(41px);
        -webkit-transform: translateY(41px);
    }
}

//  -End-
</style>
