import ls from "../../utils/localStorage";

export default{
    state:{
        game_info: ls.getItem('game_info') || {}, // 当前启动游戏信息
        game_areas: null, // 需要选区时，区列表信息
        launcher_state: 'normal', // 启动状态 'minimized':'normal':'hidden'
        game_state: "no-gaming", // 游戏状态
        game_error: '', // 错误提示
        selected_game: null, // 当前选择的游戏信息
        playing_game: null, // 当前正在玩的游戏或正在排队的游戏
        queue_ranking: 1, // 队列排名
        session_id: '', // 用户游戏过程的会话id
        game_input: false,
        now_game_area: '', // 当前游戏区
        game_keyBtn: [], // 按键配置信息
        game_virBtn: [], //
        game_prompt_msg: '', //游戏提示信息
        game_input_msg: '', //游戏输入框信息
        game_input_type: '',
        recharge_url: '' // 充值链接
    },
    mutations:{
        RECHARGE_URL(state,recharge_url) {
            state.recharge_url = recharge_url
        },
        GAME_VIRBTN(state,game_virBtn) {
            state.game_virBtn = game_virBtn
        },
        GAME_KEYBTN(state, game_keyBtn) {
            state.game_keyBtn = game_keyBtn
        },
        // 获取用户游戏过程的会话id
        SESSION_ID(state, session_id) {
            state.session_id = session_id
            ls.setItem('session_id', session_id)
        },
        // 文本输入
        GAME_INPUT(state, game_input) {
            state.game_input = game_input
        },
        // 游戏输入框原有信息
        GAME_INPUT_MSG(state, msg) {
            state.game_input_msg = msg
        },
        // 游戏输入框识别类型
        GAME_INPUT_TYPE(state, game_input_type) {
            state.game_input_type = game_input_type
        },
        //当前游戏区
        NOW_GAME_AREA(state, now_game_area) {
            state.now_game_area = now_game_area;
        },
        // 获取游戏信息
        GAME_INFO(state, game_info) {
            state.game_info = game_info
            ls.setItem('game_info', game_info)
        },
        // 游戏区域
        GAME_AREAS(state,game_areas){
            state.game_areas = game_areas;
            sessionStorage.setItem('GAME_AREAS',game_areas);
        },
        // 启动状态
        Launcher_State(state,launcher_State){
            state.launcher_state = launcher_State;
        },
        // 游戏状态
        GAME_STATE(state, game_state) {
            state.game_state = game_state
            sessionStorage.setItem('GAME_STATE',game_state);
        },
        // 游戏错误提示
        GAME_ERROR(state,game_error){
            state.game_error= game_error;
        },
        // 选择的游戏信息
        SELECTED_GAME(state,selected_game){
            state.selected_game = selected_game;
        },
        // 正在玩或正在排队的游戏
        PLAYING_GAME(state,playing_game){
            state.playing_game = playing_game;
        },
        // 队列排名
        QUEUE_RANKING(state,queue_ranking){
            state.queue_ranking = queue_ranking;
        },
        //游戏提示信息
        GAME_PROMPT_MSG(state, msg){
            state.game_prompt_msg = msg;
        },
    },
    actions:{
    }
}
