import store from '@/store'
import API from '@/api/api'

export default {
    //刷新csrf token
    init_csrf_token(_this) {
        API.getCsrfToken({}).then((response) => {
            let res = response.data
            if (!res) {
                return
            }
            if (res.status == 1) {
                let csrf_token = res.data.csrf_token
                localStorage.setItem('csrf_token', csrf_token)
            } else {
                _this.$message.show('csrf token 出错，请刷新页面重试！', 'danger')
            }
        })
    },
    //获取用户信息
    request_user_info() {
        API.getUserInfo({}).then((response) => {
            let res = response.data
            if (res && res.status == 1) {
                if (res.data) {
                    const user = res.data;
                    store.dispatch('login', user)
                } else {
                    store.dispatch("logout");
                }
            } else {
                store.dispatch("logout");
            }
        })
    },

    // 获取用户消息列表
    get_user_msg_list() {
        // 获取数据
        API.getMsgList().then(response => {
            let res = response.data;
            if (res.status) {
                store.dispatch('setUserMsg', res.data.feedback_reply_count);
            }
        })
    },

}
