//本地游戏状态管理
// no-gaming:无游戏运行
// verifying:正在验证身份
// playing-conflict:存在未断开的链接
// need-to-confirm:等待，需要用户确认是否继续原有排队
// waiting-select-area：等待用户选择游戏区
// waiting:正在排队等待
// ready:启动参数已准备好
// playing:游戏中
// waiting-exit:等待确认退出游戏
// game-closed:游戏已结束
// force-close-pre-connection:正在强制下线上一个连接
import store from '@/store'
export default {
    setGameState(game_state) {
        store.commit('GAME_STATE', game_state);
        //sessionStorage中存放一份备用
        sessionStorage.setItem('GAME_STATE',game_state);
    },
    getGameState(){
        return store.state.game.game_state;
        //或者从sessionStorage中读取
        // return sessionStorage.getItem('GAME_STATE')
    },

    launchGame(){
        this.setGameState('no-gaming');
    },

    verifying(){
        this.setGameState('verifying');
    },

    gameReady(){
        this.setGameState('ready');
    },

    startGame(){
        this.setGameState('playing');
    },

    playConflict(){
        this.setGameState('playing-conflict');
    },

    waitingSelectArea(){
        this.setGameState('waiting-select-area');
    },
    waitingGame(){
        this.setGameState('waiting');
        store.commit('GAME_ERROR', '');
    },

    waitingExitGame(){
        store.commit('Launcher_State', 'normal');
        this.setGameState('waiting-exit');
    },

    continueGame(){
        store.commit('Launcher_State', 'minimized');
        this.setGameState('playing');
    },
    
    stopGame(error_msg){
        this.setGameState('game-closed');
        store.commit('Launcher_State', 'normal');
        if(error_msg){
            this.setGameError(error_msg);
        }
        this.setSelectedGame(null);
        this.removeSessionId();
    },

    getGameError(){
        return store.state.game.game_error;
    },

    setGameError(error_msg){
        let _error  = error_msg ? error_msg : (this.getGameError() ? this.getGameError() : '');
        store.commit('GAME_ERROR', _error);
    },

    needConfirm(){
        this.setGameState('need-to-confirm'); 
    },

    //当前进行中的游戏，从选择排队开始-游戏窗口关闭(或游戏结束)
    setSelectedGame(selected_game){
        store.commit('SELECTED_GAME', selected_game);
        this.setPlayingGame(selected_game);
        if(!selected_game){
            localStorage.removeItem('SELECTED_GAME');
        }else{
            localStorage.setItem('SELECTED_GAME',JSON.stringify(selected_game)); 
        }
    },

    getSelectedGame(){
        return  store.state.game.selected_game;
    },

    setPrevSelectedGame(game_id,game_name,game_pic){
        let _prevSelectedGame={
            game_id:game_id,
            game_name:game_name,
            game_pic:game_pic,
        }
        localStorage.setItem('PREV_SELECTED_GAME',JSON.stringify(_prevSelectedGame)); 
    },

    getPrevSelectedGame(){
        let selected_game = JSON.parse( localStorage.getItem('PREV_SELECTED_GAME'));
        return selected_game;
    },

    replaceSelectedGame(){
        let prev_game = this.getPrevSelectedGame();
        if(prev_game){
            localStorage.removeItem('SELECTED_GAME');
            this.setSelectedGame(prev_game);
            this.removePrevSelectedGame();
        }
    },

    removePrevSelectedGame(){
        localStorage.removeItem('PREV_SELECTED_GAME');
    },

    setQueueRanking(queueRanking){
        store.commit('QUEUE_RANKING', queueRanking);
    },

    setPlayingGame(game){
        store.commit('PLAYING_GAME', game);
    },

    getPlayingGame(){
        return store.state.game.playing_game;
    },

    // 获取用户游戏过程的会话id
    getSessionId() {
        return store.state.game.session_id ? store.state.game.session_id : '';
    },

    // 移除缓存中的用户游戏过程的会话id
    removeSessionId() {
        localStorage.removeItem('session_id');
    },

    //获取当前的游戏区
    getNowGameArea() {
        return store.state.game.now_game_area || '';
    },

    //强制下线上一个连接
    forceClosePreConnection(msg) {
        this.setGameState('force-close-pre-connection');
        store.commit('GAME_PROMPT_MSG', msg);
    },

    //获取游戏提示信息
    gamePromptMsg(){
        return store.state.game.game_prompt_msg || '';
    }
}