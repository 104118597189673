import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login'
import certification from './modules/certification'
import userCenter from './modules/userCenter'
import game from './modules/game'
import chat from './modules/chat'

Vue.use(Vuex)

const store = new Vuex.Store({
  state:{ // 存放项目的公共数据
    loadingStatus: false, // loading
    messageType: false, // 提示信息类型
    messageText: '', // 提示信息
    baseUrl: 'https://dygame.cn'
  },
  mutations:{
    CHANGE_LOADING(state, loadingStatus) {
      state.loadingStatus = loadingStatus;
    },
    MESSAGE_TYPE(state,messageType) {
      state.messageType = messageType;
    },
    MESSAGE_TEXT(state, messageText) {
      state.messageText = messageText;
    },
  },
  modules: {
    login, certification, game, userCenter, chat
  }
})

export default store
