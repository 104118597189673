import Vue from 'vue'

const eventBus = new Vue({})

export default {
  install(Vue) {
    Object.defineProperty(Vue.prototype, '$eventBus', {
      value: eventBus
    })
  }
}