<template>
    <div>
        <!-- 评论列表 -->
            <div :class="[item.comment_id,'commentInfo',defaultObj.switch? 'minStyle': '']">
                <img  class="userAvatar_max" :src="item.user.avatar" />
                <div class="commentInfo_item">
                    <div class="commentInfo_avatar">
                        <img class="userAvatar_min" :src="item.user.avatar" />
                        <h3 v-if="defaultObj.level == 2">
                            {{ item.user.name }}<span class="levelAnswer">回复</span>{{ item.target.name }}
                            <svg v-if="item.refine_select" class="hotIcon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="-0.000976562" width="16" height="16" rx="3" fill="#F04142"/>
                                <path d="M12.344 10.35C12.542 10.35 12.696 10.251 12.828 10.064C13.015 9.778 13.136 9.151 13.191 8.183L12.322 7.754C12.322 8.106 12.3 8.436 12.267 8.733C12.223 9.041 12.168 9.206 12.113 9.206C12.069 9.206 12.014 9.107 11.948 8.909C11.794 8.469 11.728 7.677 11.728 6.522V4.278H9.48402V2.958H8.36202V4.278H7.24002V5.4H8.36202C8.34002 5.873 8.29602 6.324 8.21902 6.742C8.01002 6.61 7.79002 6.478 7.57002 6.346L6.96502 7.248C7.27302 7.424 7.58102 7.622 7.88902 7.853C7.57002 8.612 7.09702 9.228 6.45902 9.701L7.30602 10.46C7.94402 9.965 8.45002 9.338 8.81302 8.579C9.16502 8.876 9.51702 9.217 9.86902 9.591L10.518 8.601C10.1 8.183 9.67102 7.809 9.22002 7.457C9.37402 6.841 9.46202 6.148 9.48402 5.4H10.606V6.522C10.606 8.216 10.848 9.338 11.321 9.866C11.585 10.174 11.926 10.339 12.344 10.35ZM3.13702 4.223V5.345H4.56702V6.94C4.00602 7.061 3.43402 7.16 2.85102 7.237L3.12602 8.304C3.61002 8.216 4.08302 8.117 4.56702 8.018V8.953C4.56702 9.206 4.45702 9.338 4.25902 9.338C3.97302 9.338 3.66502 9.305 3.34602 9.261L3.61002 10.361H4.66602C5.37002 10.361 5.72202 9.987 5.72202 9.239V7.743C6.09602 7.644 6.47002 7.545 6.84402 7.435V6.335L5.72202 6.665V5.345H6.93202V4.223H5.72202V2.969H4.56702V4.223H3.13702ZM4.22602 10.592C3.85202 11.351 3.40102 12 2.89502 12.539L3.84102 13.232C4.34702 12.627 4.79802 11.901 5.18302 11.054L4.22602 10.592ZM6.98702 10.911L5.90902 11.131C6.10702 11.725 6.28302 12.407 6.41502 13.188L7.57002 12.913C7.39402 12.198 7.20702 11.527 6.98702 10.911ZM9.26402 10.757L8.18602 10.988C8.49402 11.615 8.74702 12.341 8.94502 13.166L10.089 12.891C9.84702 12.121 9.57202 11.417 9.26402 10.757ZM11.354 10.405L10.419 10.988C11.046 11.802 11.541 12.539 11.904 13.199L12.861 12.528C12.509 11.923 12.014 11.219 11.354 10.405Z" fill="white"/>
                            </svg>
                        </h3>
                        <h3 v-else>{{ item.user.name }}
                            <svg v-if="item.refine_select" class="hotIcon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="-0.000976562" width="16" height="16" rx="3" fill="#F04142"/>
                                <path d="M12.344 10.35C12.542 10.35 12.696 10.251 12.828 10.064C13.015 9.778 13.136 9.151 13.191 8.183L12.322 7.754C12.322 8.106 12.3 8.436 12.267 8.733C12.223 9.041 12.168 9.206 12.113 9.206C12.069 9.206 12.014 9.107 11.948 8.909C11.794 8.469 11.728 7.677 11.728 6.522V4.278H9.48402V2.958H8.36202V4.278H7.24002V5.4H8.36202C8.34002 5.873 8.29602 6.324 8.21902 6.742C8.01002 6.61 7.79002 6.478 7.57002 6.346L6.96502 7.248C7.27302 7.424 7.58102 7.622 7.88902 7.853C7.57002 8.612 7.09702 9.228 6.45902 9.701L7.30602 10.46C7.94402 9.965 8.45002 9.338 8.81302 8.579C9.16502 8.876 9.51702 9.217 9.86902 9.591L10.518 8.601C10.1 8.183 9.67102 7.809 9.22002 7.457C9.37402 6.841 9.46202 6.148 9.48402 5.4H10.606V6.522C10.606 8.216 10.848 9.338 11.321 9.866C11.585 10.174 11.926 10.339 12.344 10.35ZM3.13702 4.223V5.345H4.56702V6.94C4.00602 7.061 3.43402 7.16 2.85102 7.237L3.12602 8.304C3.61002 8.216 4.08302 8.117 4.56702 8.018V8.953C4.56702 9.206 4.45702 9.338 4.25902 9.338C3.97302 9.338 3.66502 9.305 3.34602 9.261L3.61002 10.361H4.66602C5.37002 10.361 5.72202 9.987 5.72202 9.239V7.743C6.09602 7.644 6.47002 7.545 6.84402 7.435V6.335L5.72202 6.665V5.345H6.93202V4.223H5.72202V2.969H4.56702V4.223H3.13702ZM4.22602 10.592C3.85202 11.351 3.40102 12 2.89502 12.539L3.84102 13.232C4.34702 12.627 4.79802 11.901 5.18302 11.054L4.22602 10.592ZM6.98702 10.911L5.90902 11.131C6.10702 11.725 6.28302 12.407 6.41502 13.188L7.57002 12.913C7.39402 12.198 7.20702 11.527 6.98702 10.911ZM9.26402 10.757L8.18602 10.988C8.49402 11.615 8.74702 12.341 8.94502 13.166L10.089 12.891C9.84702 12.121 9.57202 11.417 9.26402 10.757ZM11.354 10.405L10.419 10.988C11.046 11.802 11.541 12.539 11.904 13.199L12.861 12.528C12.509 11.923 12.014 11.219 11.354 10.405Z" fill="white"/>
                            </svg>
                        </h3>
                    </div>
                    <p>{{ item.created_at }}</p>
                    <div class="commentInfo_text">{{ item.content }}</div>
                    <div class="commentInfo_btns">

                        <!-- 点赞,回复，查看回复，删除按钮 Start-->
                        <button @click="commentNice(item)" class="reviewNice">
                            <i :class="!item.self_favored? 'noNice': 'Nice'" ></i>
                            <span>{{ item.favorite_count }}</span>
                        </button>
                        <button
                            v-if="(defaultObj.review && item.reply_count != 0) && item.reply_count > 2"
                            class="reviewAnswer"
                            @click="reviewComment(item.comment_id)">
                            <i class="i-review-answer"></i>
                            <span v-if="(defaultObj.revCallback && item.reply_count <= 2) || reviewShow" :data-count="item.reply_count" :ref="'reply' + item.comment_id" >收起回复</span>
                            <span v-else :data-count="item.reply_count" :ref="'reply' + item.comment_id" >查看回复({{item.reply_count}})</span>
                        </button>
                        <button
                            v-if="defaultObj.answer && !item.self_comment"
                            class="answer"
                            @click="answerStatus(item.comment_id)">
                            <i :class="['i-answer',commentStatus[item.comment_id] ? 'active' : '',]"></i>
                            <span v-if="commentStatus[item.comment_id]">取消回复</span>
                            <span v-else>回复</span>
                        </button>
                        <button
                            v-if="(item.self_comment && $store.state.login.auth) || $store.state.login.user.phone == '166****7088' || $store.state.login.user.phone == '139****5366'"
                            class="delAnswer"
                            slot="reference"
                            @click="delAnswer(item.comment_id)">
                            <i class="i-del-answer"></i>
                            <span>删除</span>
                        </button>
                        <!-- End -->
                        <!-- 回复框 -->
                        <div
                            v-if="commentStatus[item.comment_id]"
                            class="commentInfo_answer"
                            :ref="'answer' + item.comment_id">
                            <div class="answerInput_box">
                                <el-input
                                    :class="'answerInput_textarea'"
                                    type="textarea"
                                    :placeholder="'回复:' + item.user.name"
                                    :autosize="{ minRows: 1, maxRows: 10 }"
                                    :ref="'commentRefEle' + item.comment_id"
                                    maxlength="300"
                                    show-word-limit
                                    v-model="commentText[item.comment_id]">
                                </el-input>
                                <div class="hideBox"></div>
                                <!-- 表情按钮 -->
                                <button :class="['answerInput_emoji','blur_'+item.comment_id]"  @click="answerEmojiStatus(item.comment_id)"></button>

                                <!-- 所有表情的框 Start-->
                                <div :class="['emojiList','blur_'+item.comment_id]" v-if="commentEmoji[item.comment_id]" >
                                    <ul :class="'blur_'+item.comment_id">
                                        <li :class="'blur_'+item.comment_id" v-for="( itemC, indexC ) in commentEmojiPage" v-show="indexC == commentEmojiActive" :key="indexC">
                                            <div :class="'blur_'+item.comment_id" v-for="(itemP, indexP) in itemC" :key="indexP" @click="commentInputeEmoji(itemP, item.comment_id)">
                                                {{ itemP }}
                                            </div>
                                        </li>
                                    </ul>
                                    <div :class="['emojiNav_btn','blur_'+item.comment_id,]">
                                        <a :class="'blur_'+item.comment_id" v-for="(itemC, indexC) in commentEmojiPage" :key="indexC" @click="commentEmojiActive = indexC">{{ indexC + 1 }}</a>
                                    </div>
                                </div>
                                <!-- End -->
                            </div>
                            <button class="answerSubmit" @click="submitAnswer(item.comment_id)">
                                发布
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    name: "GameDetail",
    props: {
        item: Object,     // 评论信息
        reviewShow:false,
        /**dispose: 下
         *  review：    Boolean true 开启查看回复按钮, 默认需要
         *  reviewEven: Boolean false开启查看回复按钮回调事件 默认关闭
         *  answer:     Boolean true 开启回复按钮, 默认需要
         *  answerUser: Boolean true 开启显示回复对象名称 默认开启
         *  swich:      Boolean true 开启小列表样式   默认开启
         *  level: number ''         级别区分暂时只到二级
         *  delClass: Number,        删除类名设置
         */
        dispose: Object,    //一些配置
        parentId:Number
    },
    data() {
        return {
            emojis: [],
            emojisId: 0,            //当前表情ID
            answerId: 0,            //当前回复ID
            selectedCount: 0,       //精选评论个数
            commentStatus: {},      //回复框的状态 true || false
            commentText: {},        //回复框输入内容
            commentEmoji: {},       //表情开启关闭状态
            commentEmojiPage: [],   //表情分页数据
            commentEmojiActive: 0,  //表情分页导航控制
            defaultObj:{}
        };
    },
    created(){
        this.emojis = this.dispose.emojis;
        // 控制表情无焦点隐藏
        this.emojiHideBlur();
        // 表情分页数据处理
        this.commentEmojiDataHandle();
        // 默认值处理
        this.disposeDefaultEven();
    },
    methods: {
        emojiHideBlur(){
            let _this = this;
            document.addEventListener('click', (e) => {
                let name = e.target.className;

                if(name.includes('blur_' + _this.emojisId)){
                    // 确认是否是表情按钮
                    _this.$set(_this.commentEmoji, _this.emojisId, true);
                }else if(name != 'el-textarea__inner'){
                    // 同时非编辑框也关闭
                    _this.$set(_this.commentEmoji, _this.emojisId, false);
                }
            })
        },
        commentEmojiDataHandle() {
            /**
             * count： 表情分页数据处理
             * space： 控制一页表情最大数
             */
            let count = -1,
                space = 36;

            this.emojis.map((item, index) => {
                if (index % space == 0) {
                    this.commentEmojiPage.push([]);
                    count++;
                }
                this.commentEmojiPage[count].push(item);
            });
        },
        commentNice(item) {
            // 点赞功能
            if (this.$store.state.login.auth) {
                item.self_favored
                    ? (item.favorite_count -= 1)
                    : (item.favorite_count += 1);
                item.self_favored == 0
                    ? (item.self_favored = 1)
                    : (item.self_favored = 0);

                let a = item.self_favored;
                let b = item.comment_id;

                // 防止点赞过于频繁
                if (this.timer) clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    this.$API.addGameNice({ favor: a, comment_id: b });
                }, 1000);
            }
        },
        disposeDefaultEven(){
            let defaultObj = {
                switch: true,
                review: true,
                answer: true,
                answerUser: true,
                revCallback: false,
                level: '',
                delClass: '',
                lowStatus:true,
            }


            for (const key in defaultObj) {
                if(this.dispose[key] != undefined){
                    defaultObj[key] = this.dispose[key] ?? defaultObj[key];
                }
            }
            this.defaultObj = defaultObj;

        },
        answerStatus(comment_id) {
            // comment_id:每条评论的 id
            // 回复评论框的显示与隐藏
            if(this.$store.state.login.auth){

                if(this.answerId != comment_id){
                    this.$set( this.commentStatus, this.answerId, false);
                }
                this.$set( this.commentStatus, comment_id, !this.commentStatus[comment_id]);

                this.answerId = comment_id;
            }else{
                this.titlePlugin("登录后才能回复哦！")
            }
        },
        answerEmojiStatus(comment_id) {
            // 表情弹出事件
            // 与之前点击不是同一个进入
            if(this.emojisId != comment_id){
                // 先关闭前一个
                this.$set(this.commentEmoji, this.emojisId, false);
                // 后开启新的
                this.$set(this.commentEmoji, comment_id, true);
                //存储Id,用以关闭
                this.emojisId = comment_id;
            }
        },
        delAnswer(comment_id){
            // 删除element 的 messageBox组件
            this.$confirm('是否确认删除该条评论?', '删除', {
                customClass: this.defaultObj.delClass,
                cancelButtonClass:'delCancel',
                confirmButtonClass:'delConfirm',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                'modal-append-to-body':false,
                type: 'warning',
            }).then(() => {
                this.delAnswerInterface(comment_id)
            }).catch(() => {
                console.log('取消');
            });
        },
        delAnswerInterface(comment_id){
            // 删除自己的评论
            this.$API.delComment({comment_id}).then((res)=>{

                if(res.data.status == 1){
                    // 说明最后一个
                    let parent_id = 0;
                    if(this.defaultObj.level == 2) parent_id = this.parentId;

                    this.$emit("callBackComment", {level: this.defaultObj.level, parent_id, comment_id});
                    // 待优化
                    if(this.defaultObj.lowStatus){
                        this.parentNewData();
                    }
                    // 获取新数据
                    this.titlePlugin("删除成功！",true);
                }
            });
        },
        commentInputeEmoji(item, comment_id) {
            // 点击表情，对应文本框事件
            const myField = this.$refs["commentRefEle" + comment_id].$el.children[0];
            // 是否已输入，输入进入处理，未输入过直接赋值
            if (myField.selectionStart || myField.selectionStart === 0) {
                let startPos = myField.selectionStart;
                let endPos = myField.selectionEnd;
                // 0到光标起始位置 + 拼接的内容 + 光标起始到结束位置
                this.$set(
                    this.commentText,
                    comment_id,
                    myField.value.substring(0, startPos) + item + myField.value.substring(endPos, myField.value.length)
                );
                // 重新设定光标位置
                this.$nextTick(() => {
                    myField.focus();
                    myField.setSelectionRange(
                        endPos + item.length,
                        endPos + item.length
                    );
                });
            } else {
                this.$set(this.commentText, comment_id, item);
            }
        },
        reviewComment(comment_id) {
            // 查看更多
            let revBtn = this.$refs['reply'+comment_id];
            let replyCount = revBtn.dataset.count,
                replyStatus = '';
            if(this.defaultObj.revCallback){
                // 回复收起展开
                let text = revBtn.innerHTML;
                    if(text.includes('收起回复')){
                        replyStatus = true;
                        revBtn.innerHTML = `查看回复(${replyCount})`;
                    }else{
                        replyStatus = false;
                        revBtn.innerHTML = '收起回复';
                    }
                    revBtn.innerHTML = text.includes('收起回复')? `查看回复(${replyCount})` : '收起回复';
            }

            // 获取自身条数
            // 该方法点击查看更多触发
            this.$emit("reviewDialogEle", { comment_id,replyCount,revCallback: this.defaultObj.revCallback,replyStatus});
        },
        setCommentCount({comment_id,newReplyCount}){

            let repBtn = this.$refs['reply'+comment_id];
            if(repBtn){
                repBtn.dataset.count = newReplyCount
                if(repBtn?.length > 0)
                repBtn.innerText = `查看回复(${newReplyCount})`;
            }
        },
        submitAnswer(comment_id) {
            // 回复发布事件
            let content = this.commentText[comment_id];

            if (!content) {
                this.titlePlugin("评论不能为空！");
            } else {
                if (content.length <= 300) {
                    this.$API.addGameComment({ comment_id, content }).then((res) => {
                            if (res.data.status == 1) {

                                this.titlePlugin("回复成功！",true);

                                // 回复框复原各状态
                                this.$set(this.commentText, comment_id, "");        //回复框输入内容
                                this.$set(this.commentStatus,comment_id,false);     //回复框的状态 true || false
                                this.$set(this.commentEmoji, comment_id, false);    //表情开启关闭状态
                                // 获取新数据
                                if (this.defaultObj.level == 1) {
                                    let repBtn = this.$refs['reply'+comment_id];
                                    if(this.defaultObj.revCallback && repBtn){
                                        repBtn.innerHTML = '收起回复';
                                    }
                                    this.parentNewData();
                                    this.$emit('reviewDialogEle', {comment_id,bool:true});
                                }else{
                                    this.parentNewData();
                                    this.$emit("callBackComment", {comment_id: this.parentId,bool: true,});
                                }
                            }
                        });
                } else {
                    this.titlePlugin("评论字数过多，请删除超出的部分！");
                }
            }
        },
        parentNewData(){
            // 请求成功父级回调获取最新数据
        let data = {},
            funName = "";
            if (this.defaultObj.level == 1) {
                funName = "callBackComment";
            } else {
                // 二级 bool 告诉二级请求，这个是是二级界面触发的
                funName = "reviewDialogEle";

                data = {
                    level: this.defaultObj.level,
                    comment_id: this.parentId,
                    bool: true,
                };
            }
            this.$emit(funName, data);
        },
        titlePlugin(text,bool){
            /**
             * bool:默认不传，失败状态
             * text：提示内容
            */
            if(bool)this.$store.commit("MESSAGE_TYPE", true);
            this.$store.commit("MESSAGE_TEXT", text);
        }
    },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1200px) {
}
@media screen and (max-width: 1200px) and (min-width: 1000px) {
}
@media screen and (max-width: 1000px) and (min-width: 700px) {
    }
@media screen and (max-width: 700px) and (min-width: 500px) {
    .commentInfo .commentInfo_item .commentInfo_avatar h3{
        width: 87% !important;
    }
    .commentInfo{
        padding-top: 10px;
        .commentInfo_item{
            .commentInfo_text{
                margin-bottom: 10px;
            }
        }
    }
    .commentInfo.minStyle{
        .commentInfo_item{
            .userAvatar_min{
                display: inline-block !important;
            }
        }
    }
}
@media screen and (max-width: 500px) {
    .commentInfo .commentInfo_item .commentInfo_avatar h3{
        width: 80% !important;
    }
    .commentInfo {
        padding-top: 10px;

        .commentInfo_item{
            h3 {
                display: inline-block !important;
            }
            .commentInfo_text{
                margin-bottom: 10px;
            }
            .commentInfo_answer{
                .emojiList{
                    left: 50%;
                    margin-left: -150px;
                }
            }
        }
    }
    .commentInfo.minStyle{
        .commentInfo_item{
            .userAvatar_min{
                display: inline-block !important;
            }
        }
    }
}

div,p,h3,span,img,h2,p {
    margin: 0;
    padding: 0;
}
.commentInfo_title{
    border-bottom: 1px solid #e6e6e6;
}
.commentInfo {
    padding: 26px 10px 0 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    img {
        width: 56px;
        height: 56px;
        border-radius: 28px;
        margin-right: 16px;
    }
    .commentInfo_item {
        width: 100%;
        min-height: 150px;
        // overflow-x: hidden;
        position: relative;
        padding-right: 10px;
        border-bottom: 1px solid #e6e6e6;
        h3 {
            height: 24px;
            font-size: 18px;
            font-weight: 500;
            color: #222222;
            line-height: 24px;
            .levelAnswer {
                margin: 0px 8px;
                font-size: 14px;
                color: #8590a6;
            }
        }
        .commentInfo_avatar{
            width: 100%;
            height: 25px;
            position: relative;
            .userAvatar_min{
                width: 25px;
                height: 25px;
                float: left;
                display: none;
            }
            h3{
                vertical-align: text-bottom;
                overflow: hidden;
                white-space:nowrap;
                text-overflow:ellipsis;
            }
            .hotIcon{
                width: 17px;
                height: 17px;
                margin-top: -5px;
                margin-left: 5px;
                // display: inline-block;
            }
        }
        p {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #606062;
            line-height: 20px;
            margin: 8px 0 11px 0;
        }
        .commentInfo_text {
            width: 95%;
            font-size: 16px;
            font-weight: 500;
            color: #2f333d;
            line-height: 24px;
            margin-bottom: 20px;
            word-break: break-all;
            word-wrap: break-word;
        }
        .commentInfo_btns {
            // 点赞，查看回复，回复按钮
            margin-bottom: 10px;
            > button {
                margin-right: 8px;
                padding-left: 0;
                border: none;
                outline: none;
                cursor: pointer;
                color: #606062;
                background: none;
                align-items: center;
                display: inline-flex;
                vertical-align: bottom;
                justify-content: flex-start;
                i {
                    width: 16px;
                    height: 16px;
                    margin-right: 5px;
                    margin-bottom: 1px;
                    display: inline-block;
                    background-size: cover !important;
                }
                // 点赞
                .noNice {
                    margin-top: -4px;
                    background: url("../../assets/images/officialWebsite/game/game-nice.png") no-repeat left top;
                }
                .Nice {
                    margin-top: -4px;
                    background: url("../../assets/images/officialWebsite/game/game-nices.png") no-repeat left top;
                }
                // 查看回复
                .i-review-answer {
                    background: url("../../assets/images/officialWebsite/i-review-answer.png") no-repeat left top;
                }
                // 回复
                .i-answer.active {
                    background: url("../../assets/images/officialWebsite/i-answer-active.png") no-repeat left top;
                }
                .i-answer {
                    background: url("../../assets/images/officialWebsite/i-answer.png") no-repeat left top;
                }
                // 删除
                .i-del-answer {
                    background: url("../../assets/images/officialWebsite/i-del-answer.png") no-repeat left top;
                }
                span{
                    font-size: 14px;

                }
            }
            .reviewNice:hover >.noNice {
                margin-top: -4px;
                background: url("../../assets/images/officialWebsite/game/game-nices.png") no-repeat left top;
            }

            // 查看回复
            .reviewAnswer:hover >.i-review-answer {
                background: url("../../assets/images/officialWebsite/i-review-answer-active.png") no-repeat left top;
            }
            .delAnswer:hover >.i-del-answer {
                background: url("../../assets/images/officialWebsite/i-del-answer-active.png") no-repeat left top;
            }
            .answer:hover >.i-answer {
                background: url("../../assets/images/officialWebsite/i-answer-active.png") no-repeat left top;
            }
            // 回复评论输入框
            .commentInfo_answer {
                // 输入框与表情
                .answerInput_box {
                    width: calc(100% - 70px);
                    float: left;
                    position: relative;
                    margin-bottom: 20px;
                    .hideBox{
                        height: 25px;
                        background-color: #fff;
                        border: 1px solid #fad8a1;
                        border-top: 0;
                        border-radius: 0px 0px 4px 4px;
                    }

                    /deep/ .answerInput_textarea {
                        width: 100%;
                        .el-textarea__inner {
                            resize: none;
                            border: 1px solid #fad8a1;
                            border-bottom: 0px !important;
                            padding-right: 25px;
                            border-radius:4px 4px 0px 0px;
                        }
                        .el-textarea__inner::-webkit-scrollbar {
                            display: none;
                        }
                        .el-input__count{
                            right: 35px;
                            bottom: -22px;
                        }
                    }
                    .answerInput_emoji {
                        width: 29px;
                        height: 29px;
                        border: none;
                        outline: none;
                        position: absolute;
                        right: 2px;
                        bottom: 2px;
                        padding: 12px 0;
                        background-size: 18px 18px;
                        background-color: #ffffff;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-image: url("../../assets/images/officialWebsite/game/game-em.png");
                    }
                    .emojiList {
                        width: 300px;
                        height: 175px;
                        position: absolute;
                        bottom: -175px;
                        right: 0px;
                        padding: 10px;
                        background: #fff;
                        z-index: 15;
                        border-radius: 8px;
                        box-shadow: 0px 2px 8px 0px rgba(173, 173, 176, 0.2);
                        ul {
                            padding-left: 0px;
                            margin-bottom: 0px;
                            li {
                                z-index: 1;
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: flex-start;
                                flex: 1;
                                div {
                                    width: 24px;
                                    height: 24px;
                                    margin: 5px 0 5px 5px;
                                    cursor: pointer;
                                }
                            }
                        }
                        .emojiNav_btn {
                            position: absolute;
                            bottom: 10px;
                            width: 100%;
                            height: 20px;
                            text-align: center;
                            > a {
                                font-size: 12px;
                                width: 14px;
                                height: 14px;
                                margin: 0px 5px;
                                cursor: pointer;
                            }
                            > a:hover {
                                color: #fad8a1;
                            }
                        }
                    }
                }
                .answerSubmit {
                    // 发布按钮
                    width: 70px;
                    height: 33px;
                    border-radius: 4px;
                    float: right;
                    border: none;
                    outline: none;
                    color: #ffffff;
                    background: linear-gradient(
                        154deg,
                        #fad8a1 0%,
                        #e3af6d 100%
                    );
                }
            }
        }
    }
}

// 小样式
.commentInfo.minStyle{
    .commentInfo_item{
        border-bottom: none !important;
        >p,
        .commentInfo_text,
        .commentInfo_btns{
            padding-left: 45px;
        }
    }
    .commentInfo_item:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        margin-left: 45px;
        width: calc(100% - 45px);;
        
        height: 1px;
        background-color: #e6e6e6
    }
    .userAvatar_max{
        display: none !important;
    }
    .userAvatar_min{
        display: block !important;
    }
}

</style>
<style lang="scss">
@media screen and (max-width: 700px) and (min-width: 500px) {
    .delMsgBox{
        width: 100% !important;
    }
}
@media screen and (max-width: 500px) {
    .delMsgBox{
        width: 100% !important;
    }
}

.delMsgBox{
    // 游戏详情界面删除弹框确认取消按钮样式
    .delCancel:hover,
    .delCancel:focus {
        color:#606266;
        border-color: #cea567;
        background: #ecd5b281;
        outline: none;
    }

    .delConfirm,
    .delConfirm.is-active,
    .delConfirm:active,
    .delConfirm:focus
    {
        color: #FFF;
        outline: none;
        border-color: #cf9236;
        background: linear-gradient(179deg, #FFDC96 0%, #F1AE50 100%);
    }

    .delConfirm:hover {
        outline: none;
        border-color: #d19d4f;
        background: linear-gradient(154deg, #FAD8A1 0%, #E3AF6D 100%);

    }
}
</style>
