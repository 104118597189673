import md5 from "js-md5";
let base64 = require("js-base64").Base64;

const Blowfish = require('javascript-blowfish');
export default {
    wsMessageJSON(msg) {
        return this.MyBase64Encode(JSON.stringify(msg));
    },

    wsMessageBody(action_type, msg, _status, session_id, game_id, cli_session, game_area_id, text) {
        let str = "";
        if (game_area_id) {
            str = {
                action: action_type,
                game_id: game_id ? game_id : "",
                status: _status ? _status + "" : "",
                session_id: session_id || "",
                msg: msg ? msg : "",
                cli_session: cli_session ? cli_session : "",
                game_area_id: game_area_id ? game_area_id : "",
                text: text ? text : '',
            };
        } else {
            str = {
                action: action_type,
                game_id: game_id ? game_id : "",
                status: _status ? _status + "" : "",
                session_id: session_id || "",
                msg: msg ? msg : "",
                cli_session: cli_session ? cli_session : "",
                text: text ? text : '',
            };
        }
        return this.MyBase64Encode(JSON.stringify(str));
    },
    // ws音视频信息
    /**
     action	int	5000011
     content string	聊天内容/举报原因
     to_user_uuid  string	私聊用户id
     reply_id  int	回复引用消息id
     user_uuids	 array	@成员列表
     mute_type  string  text:文本 / audio：语音
     mute_time	int	禁言时长 0 表示解除禁言 ; >0 表示具体禁言时长 单位:S
     msg_id	    int	文本消息id
     report_type int 举报类型
     * **/
    wsChatBody(action_type, content, to_user_uuid, reply_id, user_uuids, mute_type, mute_time, msg_id, report_type, user_uuid) {
        let str = {
            action: action_type,
            content: content,
            to_user_uuid: to_user_uuid,
            reply_id: reply_id ? reply_id : 0,
            user_uuids: user_uuids ? user_uuids : [],
            mute_type: mute_type ? mute_type : '',
            mute_time: mute_time ? mute_time : 0,
            msg_id: msg_id ? msg_id : 0,
            report_type: report_type ? report_type : 0,
            user_uuid: user_uuid ? user_uuid : ''
        };
        return this.MyBase64Encode(JSON.stringify(str));
    },


    MyBase64Encode(msg) {
        let encryptMsg = base64.encode(msg);
        encryptMsg = encryptMsg.replace(/=/g, "^");
        encryptMsg = encryptMsg.replace(/\//g, ".");
        let newEncryptMsg = "";
        for (let j = 0; j < encryptMsg.length; j++) {
            if (j % 2 === 0) {
                if (j + 1 >= encryptMsg.length) {
                    continue;
                }
                newEncryptMsg += encryptMsg[j + 1];
            } else {
                newEncryptMsg += encryptMsg[j - 1];
            }
        }
        return md5(newEncryptMsg).substring(1, 6) + newEncryptMsg;
    },

    MyBase64Decode(msg) {
        let m5 = msg.substring(0, 5);
        let msgBody = msg.substring(5);
        let newMsg = "";
        if (m5 !== md5(msgBody).substring(1, 6)) {
            return "";
        }
        for (let i = 0; i < msgBody.length; i++) {
            if (i % 2 === 0) {
                if (i + 1 >= msgBody.length) {
                    continue;
                }
                newMsg += msgBody[i + 1];
            } else {
                newMsg += msgBody[i - 1];
            }
        }
        newMsg = newMsg.replace(/\./g, "/");
        newMsg = newMsg.replace(/\^/g, "=");
        return base64.decode(newMsg);
    },
    encryptWsMessage(msg) {
        const key = "secret key";
        const bf = new Blowfish(key);
        // console.log("Blowfish encrypt text by key: " + key);
        // Encryption
        const encrypted = bf.encrypt(msg);
        let encryptedMime = bf.base64Encode(encrypted);
        // console.log(encryptedMime);
        return encryptedMime;
    },
    decryptWsMessage(encrypt_msg) {
        return bf.decrypt(bf.base64Decode(encrypt_msg));
    },

    systemBase64Encode(msg) {
        return base64.encode(msg);
    },

    systemBase64Decode(msg) {
        return base64.decode(msg);
    }
}
