const neffos = require('neffos.js');
const { detect } = require('detect-browser');
import API from '@/api/api';
import store from '@/store'
import localGameState from '@/utils/localGameState';
import lcgWsUtil from '@/utils/lcgWsUtil'
import helper from "@/utils/helper";
import gameChat from '../gameChat/index'

export default {
    connectWsServer,
    requestWSToken,
    wsServer,
    continueLastQueue,
    reQueue,
    quiteQueue,
    forceStopOtherGame,
    stopGame,
    playingGame,
    continueGame,
    confirmExitGame,
    submitGameArea,
    startGameReview,               // 跳转游戏界面业务代码
}

let heartbeatTimer = null;
let queryRankTimer = null;
let webSocketConnection = null;

/**
 * 请求链接ws的token
 * @param game_id
 * @param onSuccess
 * @param onFailed
 */
function requestWSToken(game_id,onSuccess,onFailed){
    localGameState.verifying();
    API.getStartCloudGame({ game_id: game_id })
        .then(response => {
            let res = response.data;
            if (res.status == 1) {
                let _server_url = res.data.ws_token.ws_server;
                let ws_token = res.data.ws_token.token;
                let ws_server = wsServer(_server_url, ws_token);
                onSuccess(ws_server);
            }
            // else {
            //     let _error_message = res.message;
            //     localGameState.stopGame(_error_message);
            //     // console.log('操作失败:'+result_code + res.message);
            //     onFailed('操作失败:' + _error_message);
            // }
        })
        // .catch(error => {
        //     //Promise.reject(error);
        //     localGameState.stopGame(error);
        //     onFailed(error);
        // });
}

/**
 * 链接ws server
 * @param ws_server
 * @param onWsConnected
 * @param onRankChanged
 * @param onWsError
 * @param onWsDisConnected
 * @returns {Promise<void>}
 */
async function connectWsServer(ws_server,onWsConnected,onRankChanged,onWsError,onWsDisConnected) {
    localGameState.waitingGame();
    try {
        const browser = detect();
        const conn = await neffos.dial(
            ws_server,
            {
                launchGame: {
                    _OnNamespaceConnected:function(nsConn, msg){
                        _onWsConnected(nsConn,msg,onWsConnected)
                    },
                    _OnNamespaceDisconnect: function (nsConn, msg) {
                        _onWsDisConnected(nsConn,msg,onWsDisConnected)
                    },
                    queue: function(nsConn, msg){
                        _onQueueResult(nsConn, msg,onRankChanged)
                    },
                    report: function(nsConn, msg) {
                        _onReportResult(nsConn, msg);
                    },
                    gamingControlFlow:function(nsConn, msg){
                        _onGamingControlResult(nsConn, msg);
                    }

                },
            },
            {
                headers: {
                    "app_id": "cn.dygame.web",
                    "app_key": "VvmvxZuu1pfPYgRqBUslZW0X-D1tiIQgLtFmZQ9iKIY",
                    "browser_version":browser.version,
                    "browser_name": browser.name,
                }
            }
        );
        await conn.connect('launchGame');
    } catch (err) {
        localGameState.stopGame("服务器连接失败，请稍后重试");
        onWsError(-1,"服务器连接失败，请稍后重试");
    }
}

/**
 * 连接websocket成功后持续发送心跳包
 * @param nsConn
 * @param msg
 * @param onWsConnected
 * @private
 */
function _onWsConnected(nsConn, msg,onWsConnected) {
    webSocketConnection = nsConn;
    heartbeatTimer = setInterval(() => {
        if (nsConn) {
            let session_id = localGameState.getSessionId();
            nsConn.emit('report', lcgWsUtil.wsMessageBody(10086, '', '', session_id));
        }
    }, 10000);
    onWsConnected(nsConn);
}

/**
 * 断开websocket
 * @param nsConn
 * @param msg
 * @param onWsDisConnected
 * @private
 */
function _onWsDisConnected(nsConn, msg,onWsDisConnected) {
    if(heartbeatTimer){
        clearInterval(heartbeatTimer);
    }
    if(queryRankTimer){
        clearInterval(queryRankTimer);
    }
    console.log("WS server:断开链接"+JSON.stringify(msg));
    onWsDisConnected();

    let error_msg = localGameState.getGameError();
    if(!error_msg){
        error_msg='已与服务器断开链接';
    }

    //需要同时断开ub游戏或华为游戏，
    localGameState.stopGame(error_msg);
}

/**
 * 排队
 * @param nsConn
 * @param msg
 * @param onRankChanged
 * @private
 */
function _onQueueResult(nsConn, msg,onRankChanged) {
    localGameState.waitingGame();
    // 解密
    let body = lcgWsUtil.MyBase64Decode(msg.Body)
    console.log("WS server:接受到排队信息"+body);
    let _resultJSON = JSON.parse(body);
    let _errorCode = _resultJSON.err_code;
    let _errorMsg = _resultJSON.msg;
    _processingErrCode(_errorCode, _errorMsg);

    let _type = _resultJSON.data ? _resultJSON.data.type : "";
    switch (_type) {
        case 200001://服务器通知-正在强制下线上个连接，客户端需要等待
            localGameState.forceClosePreConnection(_errorMsg)
            break;
        case 20010:
            let game_id = _resultJSON.data.game_info.game_id;
            let background_img = '../../assets/images/game/game-bg.png';
            let _selectedGame={
                game_id: game_id,
                game_name:_resultJSON.data.game_info.name,
                game_pic:_resultJSON.data.game_info.logo,
                game_archive:_resultJSON.data.game_info.cloud_archive,             //存档按钮显示隐藏控制  0：隐藏，1：显示
                is_recharge:_resultJSON.data.game_info.is_recharge, //充值按钮显示 0：隐藏，1：显示
                game_background:_resultJSON.data.game_info.web_bg_img ? _resultJSON.data.game_info.web_bg_img : background_img,
                ranking:0,
              }
            localGameState.setSelectedGame(_selectedGame);
            let _game_area_required = _resultJSON.data.game_info.need_choose_area || 0;
            //console.log("接收到的游戏区信息" + JSON.stringify(_resultJSON.data.game_info.game_areas));
            if(_game_area_required){
                let _game_areas = _resultJSON.data.game_info.game_areas;
                localGameState.waitingSelectArea();
                store.commit('GAME_AREAS', _game_areas);
            }
            break;
        case 20066:
            //TODO  读取原有排队游戏信息,已有排队记录，需要用户确认选择
            let queue_game=_resultJSON.data.old_queue_info;
            let _game_id = queue_game.game.game_id;
            let _game_name = queue_game.game.name;
            let _game_pic = queue_game.game.logo;
            localGameState.setPrevSelectedGame(_game_id,_game_name,_game_pic);
            localGameState.needConfirm();
            break;
        case 20086:
            //启动查询排名timer
            if (!queryRankTimer) {
                queryRankTimer = setInterval(() => {
                    if (nsConn) {
                        nsConn.emit('queue', lcgWsUtil.wsMessageBody(20086, ''));
                    }
                }, 3000);
            }
            let _rank = _resultJSON.data.rank;
            store.commit('NOW_GAME_AREA', _resultJSON.data.game_area_name || '');
            onRankChanged(_rank);
            break;
        case 20099:
            // console.log("接受到启动游戏参数，可以启动游戏");
            if (queryRankTimer) {
                clearInterval(queryRankTimer);
            }
            localGameState.gameReady();
            store.commit('GAME_INFO',{launch_params: _resultJSON.data.launch_params});
            store.commit('NOW_GAME_AREA', _resultJSON.data.game_area_name || '');
            break;
        case 20097:
        case 20098:
            if (queryRankTimer) {
                clearInterval(queryRankTimer);
            }
            localGameState.gameReady();
            store.commit('GAME_INFO',{launch_params: _resultJSON.data.launch_params});
            store.commit('NOW_GAME_AREA', _resultJSON.data.game_area_name || '');
            //可以直接启动游戏

            playGameImmediately();
            break;
    }
}

/**
 * 主要涉及游戏相关配置
 * @param nsConn
 * @param msg
 * @private
 */
function _onReportResult(nsConn, msg) {
    // 解密
    let body = lcgWsUtil.MyBase64Decode(msg.Body)
    console.log("WS server:接受到Report信息"+body);
    let _resultJSON = JSON.parse(body);
    let _errorCode  = _resultJSON.err_code;
    let _errorMsg = _resultJSON.msg;
    _processingErrCode(_errorCode, _errorMsg);

    let _type = _resultJSON.data ? _resultJSON.data.type : "";
    switch (_type) {
        case 10000:
            let session_id = _resultJSON.data.session_id;
            store.commit('SESSION_ID', session_id);
            break;
        case 10099:
            // console.log("server session:"+_resultJSON.data.info.server_session);
            let server_session = _resultJSON.data.info.server_session;
            window.playTencentGame(server_session);
            break;
            //成功获取到sdo充值url—-tips：返回的url是经过encodeURIComponent后的
        case 10290:
            store.commit("RECHARGE_URL", decodeURIComponent(_resultJSON.data.url))
            break;
            //通知游戏joystick配置
        case 10601:
            let key_btn = JSON.parse(_resultJSON.data.config).beans
            store.commit("GAME_KEYBTN", key_btn)
            break;
    }
}

/**
 * 主要和服务器交互游戏的相关事件
 * @param nsConn
 * @param msg
 * @private
 */
function _onGamingControlResult(nsConn, msg) {
    // 解密
    let body = lcgWsUtil.MyBase64Decode(msg.Body)
    //console.log("WS server:接受到GamingControl信息"+body);
    let _resultJSON = JSON.parse(body);
    let _errorCode  = _resultJSON.err_code;
    let _errorMsg = _resultJSON.msg;
    _processingErrCode(_errorCode, _errorMsg);

    let _type = _resultJSON.data ? _resultJSON.data.type : "";
    switch (_type) {
        // 存档
        case 300801:
            if (_resultJSON.data.archive_result) {//存档成功
                store.commit('MESSAGE_TYPE', true)
                store.commit('MESSAGE_TEXT', '存档成功')
            } else {//存档失败
                store.commit('MESSAGE_TEXT', '存档失败')
            }
            break;
        // 切换存档
        case 300802:
            break;
        //服务器通知 玩法描述
        case 30020:
            break;
        //服务器通知 对战邀请口令
        case 30030:
            break;
        //游戏登录成功
        case 30008:
            break;
        //游戏验证失败，断开ws链接
        case 30007:
            let loginResult  = _resultJSON.data.login_result ?? 0;
            if (!loginResult) localGameState.stopGame(_errorMsg ?? '游戏验证失败');
            break;
        //游戏客户端已关闭-断开游戏
        case 30018:
            localGameState.stopGame("云端游戏已经关闭");
            break;
        //通知要进行文本输入
        case 30009:
            if (_resultJSON.data.event_type == 2) {
                return;
            }
            let _game_input_original_msg = _resultJSON.data.has_text ?? '';
            let _input_scene = _resultJSON.data.input_scene ?? ''
            store.commit('GAME_INPUT_TYPE', _input_scene)
            store.commit('GAME_INPUT_MSG', lcgWsUtil.systemBase64Decode(_game_input_original_msg));
            store.commit('GAME_INPUT', true)
            // _game_input_original_msg ? store.commit('GAME_INPUT',true) : ''
            break;
        // 游戏内打开充值链接
        case 30001:
            let _url = _resultJSON.data.url ?? '';
            _url ? window.open(_url.replaceAll("\"","")) : '';
            break;
        //t2cn预启动转发推流数据
        case 30010:
            break;
        //试玩时间到期提醒
        case 300509:
            store.commit('MESSAGE_TYPE', true)
            store.commit('MESSAGE_TEXT', '试玩即将结束')
            break;
        default:
            break;
    }
}

/**
 * 错误码处理
 * @param _errCode
 * @param _errorMsg
 * @private
 */
function _processingErrCode(_errCode, _errorMsg) {
    switch(_errCode){
        case "1"://请求错误
            store.commit('MESSAGE_TEXT', _errorMsg)
            return;
        case "2"://游戏错误，断开连接
        case "3"://游戏大区错误，断开连接
        case "30001"://客户端由于某种原因被服务器踢下线（断开连接)
        case "30002"://断开之前连接等待超时、请刷新重试~~~
        case "30020"://内部错误，排队失败断开ws连接
        case "30031"://锁定机器失败、请稍后再试~~~
        case "30059"://	加入对战错误、断开连接
        case "30061"://生产启动参数失败、断开连接
        case "30062"://创建tencent serverSession失败、断开连接
        case "30069"://内部错误启动游戏失败、断开连接
        case "40001"://客户端环境不安全、server将断开ws链接
        case "50002"://登录认证超时
        case "90001"://您的试玩时长已用完，觉得游戏好玩、那就点即下载吧~~~
        case "30021"://加入排队失败
        case "30055"://继续原有排队失败
        case "40003"://ws token失效
        case "40011"://选择游戏大区错误
            localGameState.stopGame(_errorMsg);
            return;
        case "40081"://有正在进行中的游戏，中止继续操作
            localGameState.playConflict();
            return;
        case "60001"://存档超时
            store.commit('MESSAGE_TEXT', _errorMsg)
            return;
    }
}

/**
 * 上报服务器玩家选区信息
 * @param game_area_id
 */
function submitGameArea(game_area_id){
    localGameState.waitingGame();
    if (webSocketConnection) {
       webSocketConnection.emit('queue', lcgWsUtil.wsMessageBody(20011, '提交选区信息','','','','',game_area_id));
    }
}

/**
 * 继续玩家原有的排队
 */
function continueLastQueue(){
    localGameState.waitingGame();//setGameState('waiting');

    // //当前所选游戏需要更换为
    localGameState.replaceSelectedGame();
    if (webSocketConnection) {
        webSocketConnection.emit('queue', lcgWsUtil.wsMessageBody(20055, '继续原有排队'));
    }
}

/**
 * 重新排队并上报服务器
 */
function reQueue(){
    localGameState.removePrevSelectedGame();
    localGameState.waitingGame();//setGameState('waiting');
    if (webSocketConnection) {
        webSocketConnection.emit('queue', lcgWsUtil.wsMessageBody(20057, '重新排队'));
    }
}

/**
 * 用户退出排队并上报服务器
 */
function quiteQueue(){
    // localGameState.stopGame("中断排队");
    if (webSocketConnection) {
        webSocketConnection.emit('queue', lcgWsUtil.wsMessageBody(20050, '退出排队'));
        webSocketConnection.conn.close();
    }
}

/**
 * 用户主动关闭游戏 并上报服务器
 * @param isByUser
 */
function stopGame(isByUser){
    try {
        if (!webSocketConnection) {
            localGameState.stopGame('游戏链接已断开');
            return;
        }

        if(isByUser && webSocketConnection){
            webSocketConnection.emit('report', lcgWsUtil.wsMessageBody(10001));
        }

        if(webSocketConnection){
            gameChat.stopWs()
        }
    } catch(err) {
        localGameState.stopGame('服务异常,请刷新重试');
    }
}

/**
 * 开始游戏时更改本地的游戏状态为'playing'
 */
function playingGame(){
    localGameState.startGame();
}

/**
 * 上报服务器断开之前的链接 -- ws
 */
function forceStopOtherGame(){
    if (webSocketConnection) {
        webSocketConnection.emit('queue', lcgWsUtil.wsMessageBody(20001, '强制断开之前的链接'));
        localGameState.waitingGame();
    }
}

/**
 * 获取充值链接
 */
function getRechargeLink() {
    if (webSocketConnection) {
        webSocketConnection.emit('report', lcgWsUtil.wsMessageBody(10290, '获取充值链接'));
    }
}

/**
 * 更改本地的游戏状态为'waiting-exit'
 */
function confirmExitGame(){
    localGameState.waitingExitGame();
}

/**
 * 继续游戏=>更改本地的游戏状态为'playing'
 */
function continueGame(){
    localGameState.continueGame();
}

/**
 * 拼接连接ws需要的链接
 * @param server_url
 * @param ws_token
 * @param password
 * @returns {string}
 */
function wsServer(server_url, ws_token, password = ''){
    //let ws_server = 'wss://dev.dygame.cn:8888/ws?token=' + ws_token;
    let ws_server = 'wss://dygame.com.cn:8888/ws?token=' + ws_token;
    if (server_url) ws_server = server_url + '/ws?token=' + ws_token;
    if(password) {
        ws_server = ws_server+'&password=' + password;
    }

    return ws_server;
}

/**
 * 启动游戏前的检查
 * @param gameId
 * @param useSdoAccount
 * @param _this
 */
function startGameReview(gameId, _this, useSdoAccount = false){
    let platform = '';
    if (useSdoAccount || gameId == 911) {//强制使用叨鱼账号登录
        platform = 'daoyu';
    } else {
        // 检验是否登录
        if (!store.state.login.user) {
            _this.$router.push({
                name: "Login",
            });
            return;
        }

        // 检查用户
        platform = store.state.login.user.platform ?? "";
    }

    let launchGamePath = helper.isMobile() ? 'phonegame/' : 'game/';
    if (helper.isWeiXin() && helper.isIphone()) {
         platform == "daoyu" ? _this.$router.replace({path: '/sdo/' + launchGamePath + gameId}) :
        (!store.state.login.user?.card_num ? store.dispatch('showDialog', true) : _this.$router.replace({path: '/cloud/' + launchGamePath + gameId}));
    } else {
        platform == "daoyu" ? window.open("/#/sdo/game/" + gameId) : (!store.state.login.user.card_num ? store.dispatch('showDialog', true) : window.open('/#/cloud/game/' + gameId));
    }
}

