<template>
  <div class="lcg-navbar">
     <b-container>
        <b-row>
          <b-col lg="6" md="6" cols="6">  
            <a href="/"><img id="logo-icon" src="@/assets/images/legend_header_logo.png" alt="传奇云游戏"></a>
         </b-col>
          <b-col lg="6" md="6" cols="6" class="text-right"><TheEntry /></b-col>
       </b-row>
     </b-container>
  </div>
</template>

<script>
export default {
  name: "TheHeader",
  data() {
    return {
      navList: [],
      activeNavIndex: 0,
    };
  },
  beforeCreate() {},
  methods: {
    changeNavIndex(index) {
      this.activeNavIndex = index;
    },
  },
};
</script>
<style lang="scss">
.navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.5)!important;
    border-bottom: none!important;
}
</style>