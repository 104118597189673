import router from './index'
import helper from "@/utils/helper";

router.beforeEach((to, from, next) => {
    document.body.scrollTop = 0
    // 动态更改网页的title
    if (to.meta.title) {
        document.title = to.meta.title
    }
    // 如果未匹配到路由则跳转到首页
    if (to.matched.length === 0) {
        //如果上级也未匹配到路由则跳转主页面，如果上级能匹配到则转上级路由
        from.path ? next({ path:from.fullPath}) : next({path: '/index'});
    }

    // 叨鱼用户不能访问的地址直接跳转到首页
    let user = JSON.parse(localStorage.getItem('user'));
    if (!to.matched.some(record => record.meta.sdo) && user) {
        let platform = user.hasOwnProperty('platform') ? user.platform : '';
        if (platform == 'daoyu') {
            next({
                path: '/index'
            })
        }
    }

    // 根据PC和Phone设备自动替换路由
    let routeName = to.name;
    if ((routeName == 'CloudGame' || routeName == 'Sdo' || routeName == 'Partner')) {
        helper.isIE() ? next({name: 'NoSupport', params: {gameId: to.params.id, copyHttp: document.URL}}) : ''
        if (helper.isMobile() && to.path.indexOf("/phonegame") < 0) {
            next({ path: to.fullPath.replace('/game', '/phonegame'), replace : true})
        } else if (!helper.isMobile() && to.path.indexOf("/phonegame") >= 0) {
            next({ path: to.fullPath.replace('/phonegame', '/game'), replace : true})
        }

        let gameId = to.params.id;
        if (helper.isMobile()) {
            switch (gameId) {
                case '909':
                case '9090':
                    next({ path: to.fullPath.replace('/909', '/906'), replace : true});
                    break;
                case '9030':
                    next({ path: to.fullPath.replace('/9030', '/903'), replace : true});
                    break;
            }
        } else {
            switch (gameId) {
                case '906':
                case '9060':
                    next({ path: to.fullPath.replace('/906', '/909'), replace : true});
                    break;
                case '903':
                    next({ path: to.fullPath.replace('/903', '/9030'), replace : true});
                    break;
            }
        }
    }

    // 判断该路由是否需要登录权限
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (user) {
            next()
        } else {
            next({
                path: '/auth/login',
                query: {
                    redirect: to.fullPath
                }
            })
        }
    } else {
        next()
    }
});
