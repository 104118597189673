import { httpGet, httpPost, httpDelete } from '@/api/axios'

let API = {
    // 登出
    logout : async (param) => {
        return await httpPost('webapi/v1/logout', param)
    },
    // 发送验证码
    sendVerifyCode : async (param) => {
        return await httpPost('api/v1/loginVerificationCodes', param)
    },
    // 登陆验证
    loginAuth : async (param) => {
        return await httpPost('webapi/v1/authorizations', param)
    },
    // 提交实名认证
    submitUserAuth : async (param) => {
        return await httpPost('webapi/v1/users_verified', param)
    },
    // 获取用户信息
    getUserInfo : async (param) => {
        return await httpGet('webapi/v1/user_info', param)
    },
    // 获取Token
    getCsrfToken : async (param) => {
        return await httpGet('webapi/v1/csrf_token', param)
    },
    // 获取推荐列表
    getRecommendList : async (param) => {
        return await httpGet('webapi/v2/recommend', param)
    },
    // 获取新消息列表
    getMsgList : async (param) => {
        return await httpGet('webapi/v2/feedback/new/reply/count', param)
    },
    // daoyu获取游戏页面初始化参数
    daoyuGameInitParams : async (param) => {
        return await httpPost('/webapi/v2/daoyu/game/init/params', param)
    },
    // todo  第三方试玩获取ws token的api
    getTryWsToken : async (param, header) => {
        return await httpPost('api/v1/authorizations/cloud_game_token', param, header)
    },
    // 获取android apk资源信息
    getApkResourceInfo : async (param) => {
        return await httpPost('webapi/v2/apk/resource', param)
    },
    // 获取网站充值信息
    getRechargeInfo : async (param) => {
        return await httpPost('api/v1/stk/fetch/recharge/orders', param);
    },
    // 启动云游戏
    getStartCloudGame : async (param) => {
        return await httpGet('/webapi/v2/startCloudGame', param);
    },
    // 获取新闻列表
    getNewsList : async (param) => {
        return await httpGet('/webapi/v1/news/categories', param)
    },
    // 获取新闻详情
    getNewsDetail : async (param) => {
        return await httpGet('/webapi/v1/news/infos', param)
    },
    // 获取游戏详情
    getGameDetail : async ( param) => {
        return await httpGet('/webapi/v2/game/details', param)
    },
    // 获取评论信息
    getGameComment : async ( param) => {
        return await httpGet('/webapi/v2/comment/list', param)
    },
    // 获取查看回复信息
    getReviewComment : async ( param) => {
        return await httpGet('/webapi/v2/comment/reply/view', param)
    },
    // 删除评论
    delComment : async ( param) => {
        return await httpGet('/webapi/v2/comment/delete', param)
    },
    // 添加评论信息
    addGameComment : async ( param) => {
        return await httpPost('/webapi/v2/comment/publish', param)
    },
    // 点赞记录
    addGameNice : async ( param) => {
        return await httpPost('/webapi/v2/comment/favorite', param)
    },
    // 提交个人中心-邮箱用户名修改
    submitUserCenterData : async (param) => {
        return await httpPost('/webapi/v2/user/edit', param)
    },
    // 获取个人中心-游戏记录数据
    getPlayingRecordData : async (param) => {
        return await httpGet('/webapi/v2/user/gaming/record', param)
    },
    // 获取个人中心-我的收藏数据
    getMyCollectData : async (param) => {
        return await httpGet('/webapi/v2/user/collect/game', param)
    },
    // 获取个人中心-存档记录数据
    getArchiveData : async (param) => {
        return await httpGet('/webapi/v2/user/gaming/archive', param)
    },
     // 获取个人中心-存档记录单条历史数据
    getArchiveHistory : async (param) => {
        return await httpGet('/webapi/v2/user/gaming/archive/history', param)
    },
    // 获取个人中心-获取用户历史反馈记录
    getHistoryList: async (param) => {
        return await httpGet('/webapi/v2/feedback/record', param)
    },
    // 获取个人中心-获取用户历史反馈问题与答复
    getHistoryQuestion: async (param) => {
        return await httpGet('/webapi/v2/feedback/reply/record', param)
    },
    // 获取个人中心-提交反馈问题
    submitFeedbackAnswer: async (param) => {
        return await httpPost('/webapi/v2/feedback/reply/publish', param)
    },
    // 获取意见反馈类型
    getFeedBackClassify: async (param) => {
        return await httpGet('/webapi/v2/feedback/classes', param)
    },
    // 提交意见反馈
    submitFeedBack: async (param) => {
        return await httpPost('/webapi/v2/feedback/publish', param)
    },
    // 取消收藏
    collectionNoStatue: async (param) => {
        return await httpDelete('/webapi/v1/user_collect_game', param)
    },
    // 收藏
    collectionStatue: async (param) => {
        return await httpPost('/webapi/v1/user_collect_game', param)
    },
    // 更改绑定手机号
    changeOrVerifyPhone: async (param) => {
        return await httpPost('/webapi/v2/user/changeOrVerifyPhone', param)
    },
}
export default API;