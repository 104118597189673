<template>
    <div>
        <transition name="Fade">
            <div v-show="commentStatus" :class="['sideComment',commentStatus ? '' : 'hideComment']">
                <h2>评论<span>{{avgRatings}}</span><i @click="closeDialog"></i></h2>
                <div class="sideComment_con">
                    <img :src=selfAvatar>
                    <div class="sideComment_input">
                        <el-input
                                id="commentInput"
                                ref="commentEle"
                                type="textarea"
                                :rows="3"
                                placeholder="请输入内容"
                                show-word-limit
                                v-model="textarea">
                        </el-input>
                        <section v-if="!textarea">评论</section>
                        <section v-else class="sideComment_btn" @click="submitComment">评论</section>
                    </div>
                </div>
                <div class="comment_setting">
                    <div class="setting_em"></div>
                    <div class="setting_start">
                        <span>轻点发布我的评价</span>
                        <el-rate v-model="valueStart"></el-rate>
                    </div>
                    <div class="setting_emoji" v-show="emojiStatus">
                        <ul >
                            <li v-for="( itemC, indexC ) in commentEmojiPage" v-show="indexC == commentEmojiActive" :key="indexC">
                                <div v-for="(itemP, indexP) in itemC" :key="indexP" @click="commentInputeEmoji(itemP)">
                                    {{ itemP }}
                                </div>
                            </li>
                        </ul>
                        <div class="emojiNav_btn">
                            <a v-for="(itemC, indexC) in commentEmojiPage" :key="indexC" @click="commentEmojiActive = indexC">{{ indexC + 1 }}</a>
                        </div>
                    </div>
                </div>
                <div class="sideComment_com">
                    <div class="sideComment_info">
                        <div v-for="item in commentData" :key="item.id">
                            <CommentList
                                    :dispose="dispose"
                                    :item="item"
                                    :reviewShow="reviewShowS[item.comment_id]"
                                    @callBackComment="callBackComment"
                                    @reviewDialogEle="reviewDialogEle"
                                    class="sideComment_list"
                                    :ref="'commentListParent'+ item.comment_id"
                            />
                            <div :class="['childList', item.reply.length == 0 && !item.reply.length == 3? '':'active']" :ref="'childList'+item.comment_id">
                                <div >
                                    <CommentList
                                            v-for="itemC in (item.reply_count < 3? item.reply : reviewChildDataAll[item.comment_id])"
                                            :key="itemC.id"
                                            :dispose="disposeChild"
                                            :parentId="item.comment_id"
                                            :item="itemC"
                                            @callBackComment="callBackComment"
                                            @reviewDialogEle="reviewDialogEle"
                                    />
                                </div>
                                <div
                                        v-if="moreBtnMapStatus[item.comment_id] == false && item.reply_count > 10 "
                                        class="sideComment_moreBtn"
                                        @click="reviewDialogEle({comment_id: item.comment_id, pageStatus:true})">查看更多回复</div>
                                <p v-else-if="moreBtnMapStatus[item.comment_id]" class="sideComment_noMore">暂无更多回复</p>
                            </div>
                        </div>
                    </div>
                    <div v-if="moreBtnStatus" class="sideComment_moreBtn" @click="getMoreComment">查看更多评论</div>
                    <p v-else class="sideComment_noMore">暂无更多评论</p>
                </div>
            </div>
        </transition>
    </div>

</template>

<script>

    import CommentList from "@/components/officialWebsite/CommentList.vue";


    export default {
        name: "SideComment",
        props:{
            commentStatus:{
                type: Boolean,
                default: false
            }
        },
        data(){
            return {
                textarea: '',       // 评论文本
                emojis: [
                    '😀','😃','😄','😁','😆','😅','🤣','😂','🙂','🙃','😉','😊','😇','🥰','😍','🤩','😘','😗','😚','😙',
                    '😋','😛','😜','🤪','😝','🤑','🤗','🤭','🤫','🤔','🤐','🤨','😐','😑','😶','😏','😒','🙄','😬','🤥',
                    '😌','😔','😪','🤤','😴','😷','🤒','🤕','🥵','🥶','🥴','😵','🥳','😎','🤓','🧐','😕','😟','🙁','😮',
                    '😯','😲','😳','🥺','😦','😧','😨','😰','😥','😢','😭','😱','😖','😣', '😞','😓','😩','😫','🥱','😤',
                    '😡','😠','😈','👿','‍🔥'
                ],                  // 表情
                emojiStatus: false, // 表情框
                valueStart: null,   // 评星
                timer: null,
                commentData: [],    // 总评论信息
                temporaryData: [],  // 每次请求的评论信息
                count: 1,           // 点击更多评论次数
                gameId: '',
                moreBtnStatus: true,
                avgRatings: '',     // 游戏评分
                currentLimit: false, // 评论限流
                selfAvatar: '',     //个人头像
                dispose:{
                    switch:false,
                    revCallback: true,
                    lowStatus:false,
                    level: 1,          //层级
                    delClass:'delGameCommentBox'
                },
                disposeChild:{
                    review: false,
                    lowStatus:false,
                    level: 2,          //层级
                    delClass: 'delGameCommentBox'
                },
                disposeDialog:{
                    switch:false,
                    lowStatus:false,
                    review: false,
                    level: 2,          //层级
                    delClass: 'delGameCommentBox'
                },
                dialogCommentId: 0,      //弹框数据的存储
                reviewChildPage: {},     // 展开子级当前页数
                reviewChildDataAll: {},  // 展开子级所有数据
                moreBtnMapStatus:{},
                reviewShowS:{},
                nowPage:0,              //当前页数，禁止相同出现两遍
                commentEmojiPage: [],   //表情分页数据
                commentEmojiActive: 0,  //表情分页导航控制
            }
        },
        created() {
            this.dispose.emojis = this.emojis;
            this.disposeChild.emojis = this.emojis;
            this.disposeDialog.emojis = this.emojis;

            this.gameId = this.$route.params.id;
        },
        watch: {
            currentLimit (state) {
                let t = null
                state ?  t = setTimeout(()=>{this.currentLimit = false},10000) : clearTimeout(t)
            },
            commentStatus (state) {
                if (state && this.commentData.length == 0) {
                    this.getGameComment({game_id:this.gameId,page:this.count})
                }
            }
        },
        mounted() {
            // 表情分页数据处理
            this.commentEmojiDataHandle();

            // 点击其他地方隐藏设置栏
            document.addEventListener('click', (e) => {
                let ele = { btn: ".sideComment",
                    modal: "#answerDialog",
                    messgeDialog: ".el-message-box__wrapper",
                    emojiBtn: ".setting_em",
                    emojis: ".setting_emoji"
                }

                for (const k in ele) {
                    if (Object.hasOwnProperty.call(ele, k)) {
                        ele[k] = document.querySelector(ele[k]);
                        ele[k] = ele[k] == null ? true : !ele[k].contains(e.target);
                    }
                }

                if(ele.btn && ele.modal && ele.messgeDialog){
                    this.closeDialog();
                }
                ele.emojiBtn && ele.emojis? this.emojiStatus = false : this.emojiStatus = true;
            })
        },
        components:{
            CommentList
        },
        methods:{
            closeDialog(){
                this.$emit('changeCommentStatus',false)
            },
            commentInputeEmoji(item) {
                // 点击表情，对应文本框事件
                const myField = this.$refs.commentEle.$el.children[0];
                // 是否已输入，输入进入处理，未输入过直接赋值
                if (myField.selectionStart || myField.selectionStart === 0) {
                    let startPos = myField.selectionStart;
                    let endPos = myField.selectionEnd;
                    // 0到光标起始位置 + 拼接的内容 + 光标起始到结束位置
                    this.textarea = myField.value.substring(0, startPos) + item + myField.value.substring(endPos, myField.value.length)
                    // 重新设定光标位置
                    this.$nextTick(() => {
                        myField.focus();
                        myField.setSelectionRange(
                            endPos + item.length,
                            endPos + item.length
                        );
                    });
                } else {
                    this.textarea = item;
                }
            },
            commentNice(item) {
                if (this.$store.state.login.auth) {
                    item.self_favored ? item.favorite_count-=1 : item.favorite_count+=1
                    item.self_favored == 0 ? item.self_favored = 1 : item.self_favored = 0
                    let a = item.self_favored
                    let b = item.comment_id
                    if(this.timer) clearTimeout(this.timer)
                    this.timer = setTimeout(()=>{
                        this.$API.addGameNice({favor: a, comment_id: b})
                    },1500)
                }
            },
            getGameComment(obj) {
                // 获取一级评论列表
                this.$API.getGameComment(obj).then((res)=>{
                    if (res.data.status == 1) {
                        let { data } = res.data
                        this.temporaryData = data.comments
                        let limit = data.meta.limit
                        let total = data.meta.count
                        if (obj.page == 1) {
                            this.commentData = this.temporaryData
                            this.avgRatings = data.avg_ratings
                            this.selfAvatar = data.self_avatar
                        } else {
                            if( this.nowPage != obj.page ){
                                this.commentData.push(...this.temporaryData)
                                this.nowPage = obj.page;
                            }
                        }
                        if (this.count*limit>=total) {
                            this.moreBtnStatus = false
                        }
                    }
                })
            },
            submitComment() {
                let obj = {
                    game_id: this.gameId,
                    content: this.textarea,
                    rating: this.valueStart
                }
                if (!this.textarea) {
                    this.$store.commit('MESSAGE_TEXT', '评论不能为空')
                } else {
                    if (this.currentLimit) {
                        this.$store.commit('MESSAGE_TEXT', '请勿频繁评论')
                    } else {
                        this.$API.addGameComment(obj).then((res)=>{
                            if (res.data.status == 1) {
                                this.$store.commit('MESSAGE_TYPE', true)
                                this.$store.commit('MESSAGE_TEXT', '评论成功')
                                this.textarea = ''
                                this.valueStart = null
                                this.currentLimit = true
                                this.getGameComment({game_id:this.gameId,page:1})
                                document.querySelector('.sideComment_com').scrollTop = 0
                            }
                        })
                    }

                }
            },
            getMoreComment() {
                this.count++;
                this.getGameComment({game_id:this.gameId,page:this.count});
            },
            callBackComment({level, comment_id, parent_id}){

                // 删除回调
                if(level && level == 2 && this.reviewChildDataAll[parent_id]?.length > 2){
                    let count = 0;

                    let nData =  this.reviewChildDataAll[parent_id].map((item,index)=>{
                        if(item.comment_id == comment_id){
                            count = index;
                        }
                        return item;
                    });

                    this.moreBtnMapStatus[comment_id] = true;

                    nData.splice(count,1);

                    if(!(nData.length > 10)){ this.moreBtnMapStatus[comment_id] = undefined; }

                    this.$refs['commentListParent' + parent_id][0].setCommentCount({comment_id:parent_id,newReplyCount: nData.length})

                    this.$set(this.reviewChildDataAll, parent_id, nData);

                }
                this.getGameComment({game_id:this.gameId,page:this.count});
                this.reviewDialogEle({comment_id: parent_id, bool:level == 2 ?true:false})
                

            },
            reviewDialogEle({comment_id,bool,replyCount,pageStatus,replayStatus,}){
                /**
                 * 查看更多弹框请求
                 * bool:是否是二级回复，是清空该id数据
                 * data：该条"查看回复"的数据
                 * replyCount: 回头写XXX
                 * revCallback: 查看更多额外事件
                 */
                if(bool){
                    this.reviewChildDataAll[comment_id] = [];
                } else{
                    this.disposeChild.parent_id = comment_id;
                }

                let data =  this.reviewChildDataAll[comment_id],
                    page = this.reviewChildPage[comment_id]?.page || 1;

                if(pageStatus) {
                    page = parseInt(page) + 1;
                } else {
                    page = 1;
                    this.moreBtnMapStatus[comment_id] = '';
                }
                // 判断是否已有请求过后的数据
                if( data == undefined || data.length <= 0 || pageStatus){
                    this.$API.getReviewComment({comment_id,page}).then((res)=>{
                        if(res.data.status == 1){
                            let len = res.data.data.reply.length;
                            if(len > 0){
                                // 将新请查看回复存储
                                if(pageStatus){
                                    this.$set(this.reviewChildDataAll, comment_id, [...this.reviewChildDataAll[comment_id],...res.data.data.reply]);
                                }else{
                                    this.$set(this.reviewChildDataAll, comment_id, res.data.data.reply);
                                }
                                for (let i = 0; i < this.commentData.length; i++) {
                                    if(this.commentData[i].comment_id == comment_id){
                                        this.commentData[i].reply = res.data.data.reply;
                                        this.commentData[i].reply_count = res.data.data.reply.length
                                        break;
                                    }
                                }
                                // 最后一页，关闭更多，显示提示信息
                                if(page == res.data.data.meta.last_page){
                                    this.moreBtnMapStatus[comment_id] = true;
                                }

                                this.reviewChildPage[comment_id] = res.data.data.meta;

                                if(replyCount != len && this.$refs['commentListParent' + comment_id][0]){
                                    // 更新查看回复条数显示
                                    this.$refs['commentListParent' + comment_id][0].setCommentCount({comment_id,newReplyCount: len})
                                }

                                if(this.reviewChildDataAll[comment_id].length < 10){ this.moreBtnMapStatus[comment_id] = undefined; }

                                if(this.reviewChildDataAll[comment_id].length == 3){
                                    this.$set(this.reviewShowS,comment_id,true);
                                }
                  
                                this.$refs['childList'+comment_id][0].className = 'childList active';
                                

                            }else{
                                this.$refs['childList'+comment_id][0].className = 'childList'
                            }
                        }
                    });

                }else{
                    this.revCallback(comment_id);
                }
            },
            revCallback(comment_id){
                // 查看更多事件回调
                this.$refs['childList'+comment_id][0].className
                    = this.$refs['childList'+comment_id][0].className.includes('active') ?
                    'childList'
                    :'childList active';
            },
            commentEmojiDataHandle() {
                /**
                 * count： 表情分页数据处理
                 * space： 控制一页表情最大数
                 */
                let count = -1,
                    space = 36;

                this.emojis.map((item, index) => {
                    if (index % space == 0) {
                        this.commentEmojiPage.push([]);
                        count++;
                    }
                    this.commentEmojiPage[count].push(item);
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    p,div,span,i,h2{margin: 0;padding: 0}
    .sideComment{
        width: 458px;
        height: 100%;
        background: #2F333D;
        position: fixed;
        top: 0;right: 0;
        z-index: 10;
        transition: 0.5s;
        padding: 32px;
        h2 {
            margin-bottom: 23px;
            height: 26px;
            font-size: 26px;
            font-weight: 500;
            color: #E6E6E6;
            line-height: 26px;
            background-image: url("../../assets/images/officialWebsite/game/game-grade.png");
            background-repeat: no-repeat;
            background-size: 22px 26px;
            background-position: 70px center;
            position: relative;
            span {
                font-size: 26px;
                font-weight: bold;
                color: #E6E6E6;
                padding-left: 50px;
            }
            i {
                width: 26px;
                height: 26px;
                position: absolute;
                right: -10px;
                background:url("../../assets/images/game/game-dialog-x1.png")no-repeat center;
                background-size: 14px 14px;
                cursor: pointer;
            }
        }
        .sideComment_con {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            img {
                width: 48px;
                height: 48px;
                border-radius: 50%;
            }
            .sideComment_input {
                box-sizing: content-box;
                height: 140px;
                background: rgba(0, 0, 0, 0.2);
                border: 1px solid #606062;
                border-radius: 4px;
                position: relative;
                /deep/ .el-textarea {
                    background: rgba(0, 0, 0, 0);
                }
                /deep/ .el-textarea__inner {
                    width: 330px;
                    height: 88px;
                    resize: none;
                    color: #ADADB0;
                    background: rgba(0, 0, 0, 0);
                    border: none;
                    scrollbar-width: none;
                    -ms-overflow-style: none;
                }
                /deep/ .el-textarea__inner::-webkit-scrollbar {
                    display: none;
                }
                section {
                    width: 64px;
                    height: 32px;
                    background: #E6E6E6;
                    border-radius: 4px;
                    position: absolute;
                    right: 16px;
                    bottom: 12px;
                    font-size: 14px;
                    color: #351B08;
                    text-align: center;
                    line-height: 32px;
                    cursor: pointer;
                }
                .sideComment_btn {
                    background: linear-gradient(154deg, #FAD8A1 0%, #E3AF6D 100%);
                }
                .sideComment_btn:hover {
                    background: linear-gradient(159deg, #FFDC96 0%, #F1AE50 100%);
                }
            }
        }
        .comment_setting {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            margin-bottom: 20px;
            .setting_em {
                cursor: pointer;
                flex-shrink: 0;
                width: 18px;
                height: 18px;
                background: url("../../assets/images/officialWebsite/game/game-em1.png");
                background-size: 18px 18px;
                margin: 14px 0 8px 64px;
            }
            .setting_em:hover {
                background: url("../../assets/images/officialWebsite/game/game-em.png");
                background-size: 18px 18px;
            }
            .setting_start {
                display: flex;
                margin-top: 5px;
                span {
                    font-size: 12px;
                    font-weight: 400;
                    color: #ADADB0;
                    margin-right: 8px;
                }
                /deep/ .el-rate__icon {
                    font-size: 16px!important;
                }
                /deep/ .el-icon-star-on {
                    color: #D5A561!important;
                }
            }
            .setting_emoji {
                width: 285px;
                height: 175px;
                position: absolute;
                top: 40px;
                left: 64px;
                padding: 10px;
                background: #fff;
                z-index: 15;
                border-radius: 8px;
                box-shadow: 0px 2px 8px 0px rgba(173, 173, 176, 0.2);
                ul {
                    padding-left: 0px;
                    margin-bottom: 0px;
                    li {
                        z-index: 1;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        flex: 1;
                        div {
                            width: 24px;
                            height: 24px;
                            margin: 5px 0 5px 5px;
                            cursor: pointer;
                        }
                    }
                }
                .emojiNav_btn {
                    position: absolute;
                    left: 0;
                    bottom: 10px;
                    width: 100%;
                    height: 20px;
                    text-align: center;
                    > a {
                        font-size: 12px;
                        width: 14px;
                        height: 14px;
                        margin: 0px 5px;
                        cursor: pointer;
                    }
                    > a:hover {
                        color: #fad8a1;
                    }
                }
            }
        }
        .sideComment_com {
            height: 80%;
            overflow-y: auto;
            .sideComment_info {
                >div:first-child{
                    /deep/ .sideComment_list{
                        >.commentInfo{
                            padding-top: 0px;
                        }
                    }
                }
                .childList{
                    display: none;
                    // overflow-x: hidden;
                    padding-left: 72px;
                    /deep/ .commentInfo .commentInfo_item::before{
                        background-color: #606062 !important;
                    }
                }
                .childList.active{
                    display: block;
                }
                /deep/ .commentInfo{
                    width: 100%;
                    h3 { color: #ADADB0;}
                    p {  color: #888888;}
                    .commentInfo_item {
                        border-bottom: 1px solid #606062;
                        h3 {
                            color: #ADADB0;
                        }
                        p{
                            color: #888888;
                        }
                        .commentInfo_text {
                            color: #FFFFFF;
                        }
                        span {
                            color: #606062;
                        }
                    }
                }
            }
            .sideComment_moreBtn {
                cursor: pointer;
                width: 220px;
                height: 40px;
                margin: 60px auto;
                background-color: rgba(0, 0, 0, 0.2);
                background-image: url("../../assets/images/officialWebsite/game/game-row.png");
                background-repeat: no-repeat;
                background-size: 10px 8px;
                background-position: 165px center;
                border-radius: 4px;
                font-size: 14px;
                color: #ADADB0;
                text-align: center;
                line-height: 40px;
            }
            .sideComment_moreBtn:hover {
                color: #E6E6E6;
                background-image: url("../../assets/images/officialWebsite/game/game-row1.png");
            }
            .sideComment_noMore {
                width: 84px;
                height: 20px;
                margin: 50px auto;
                font-size: 14px;
                font-weight: 400;
                color: #ADADB0;
                line-height: 20px;
            }
        }
        .sideComment_com::-webkit-scrollbar {
            width: 4px;
            height: 1px;
        }
        .sideComment_com::-webkit-scrollbar-thumb {
            border-radius: 10px;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: #ADADB0;
        }
        .sideComment_com::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            background: #606062;
        }
    }
    .hideComment.sideComment{
        width: 458px !important;
        height: 100%;
        background: #2F333D;
        position: fixed;
        top: 0;
        right: -460px;
        z-index: 10;
        transition: 0.5s;
    }
    .Fade-enter,
    .Fade-leave-to {
        right: -460px;
    }
    .Fade-enter-to,
    .Fade-leave {
        right: 0;
    }

    // 回复弹框
    /deep/ #answerDialog{
        color: #E6E6E6;
        .el-dialog{
            background-color: #2F333D;
            .el-dialog__title{
                color: #E6E6E6;
            }
            .el-dialog__body{
                padding-top: 0;
            }
            .commentInfo{
                h3 { color: #ADADB0;}
                p {  color: #888888;}
                .commentInfo_item {
                    border-bottom: 1px solid #606062;
                    h3 {
                        color: #ADADB0;
                    }
                    p{
                        color: #888888;
                    }
                    .commentInfo_text {
                        color: #FFFFFF;
                    }
                    span {
                        color: #606062;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 700px) and (min-width: 500px) {
        /deep/ #answerDialog{
            .el-dialog{
                width: 100% !important;
                height: 100%;
            }
            .el-dialog{
                margin-top: 0 !important    ;
            }
        }
    }

    @media screen and (max-width: 500px) {
        /deep/ #answerDialog{
            .el-dialog{
                width: 100% !important;
            }
            .el-dialog{
                margin-top: 0px !important;
            }
        }
    }
</style>
<style lang="scss">
    .delGameCommentBox.el-message-box{
        background-color: #2F333D;
        border: none;

        .el-message-box__title{
            color: #E6E6E6;
        }
        // 游戏详情界面删除弹框确认取消按钮样式
        .delCancel:hover,
        .delCancel:focus {
            color:#606266;
            border-color: #606266;
            background: #e2ddd3;
            outline: none;
        }
        .delCancel{
            background: #E6E6E6;
        }
        .el-message-box__message{
            color: #E6E6E6;
        }
        .delConfirm,
        .delConfirm.is-active,
        .delConfirm:active,
        .delConfirm:focus
        {
            color: #FFF;
            outline: none;
            border-color: #cf9236;
            background: linear-gradient(179deg, #FFDC96 0%, #F1AE50 100%);
        }

        .delConfirm:hover {
            outline: none;
            border-color: #d19d4f;
            background: linear-gradient(154deg, #FAD8A1 0%, #E3AF6D 100%);

        }
    }
</style>
