import Queue from '../../components/gameChat/index.js'

export default{
    state:{
        room_id: '', // 当前房间号
        room_cache_list: {}, // 房间人员缓存列表信息
        room_user_show_list: {}, // 需要显示的房间人员列表信息
        room_info_list: {}, // 房间列表信息
        chat_record_list: {}, // 聊天记录列表   用户自己发的消息的状态 1发送中 0发送成功 2发送失败
        report_type: [], //  举报分类
        report_fail_msg_id: '',
        last_status: false, // 上次操作是否完成
        join_status: true, // 房间是否加入成功
        related_news_counts: 0 // 与我相关
    },
    mutations:{
        ROOM_CACHE_LIST (state, room_cache_list) {
            state.room_cache_list[room_cache_list.roomId] = room_cache_list[room_cache_list.roomId]
            let now_list = {};
            for (let i in state.room_cache_list) {
                now_list[i] = {
                    ...state.room_cache_list[i].us,
                    ...state.room_cache_list[i].owner,
                    ...state.room_cache_list[i].managers,
                    ...state.room_cache_list[i].audios,
                    ...state.room_cache_list[i].generals
                }
            }
            state.room_user_show_list = Object.assign({}, state.room_user_show_list, now_list)
        },
        ROOM_LIST_ADD (state, joiner_info) {
            if(state.last_status) return
            state.last_status = true
            let obj = state.room_cache_list;
            switch (joiner_info[joiner_info.roomId].identity) {
                case "owner":
                    obj[joiner_info.roomId].owner = joiner_info[joiner_info.roomId]
                    Queue.removeQ(joiner_info.time)
                    break;
                case "manager":
                case "sysAdmin":
                    obj[joiner_info.roomId].managers[joiner_info[joiner_info.roomId].user_uuid] = joiner_info[joiner_info.roomId]
                    Queue.removeQ(joiner_info.time)
                    break;
                case "audience":
                    (joiner_info[joiner_info.roomId].auth.ban_audio_expire_time - Date.now()/1000 <= 0) ?
                        obj[joiner_info.roomId].audios[joiner_info[joiner_info.roomId].user_uuid] = joiner_info[joiner_info.roomId]
                        : obj[joiner_info.roomId].generals[joiner_info[joiner_info.roomId].user_uuid] = joiner_info[joiner_info.roomId]
                    Queue.removeQ(joiner_info.time)
                    break;
                default:
                    return;
            }
            let user_list = {}
            for (let i in state.room_cache_list) {
                user_list[i] = {
                    ...obj[i].us,
                    ...obj[i].owner,
                    ...obj[i].managers,
                    ...obj[i].audios,
                    ...obj[i].generals
                }
            }
            state.room_user_show_list = Object.assign({}, state.room_user_show_list, user_list)
            console.log("加人", joiner_info[joiner_info.roomId].user_name)
            state.last_status = false
        },
        ROOM_LIST_DELETE (state, leaver_info) {
            if(state.last_status) return
            state.last_status = true
            let obj = state.room_cache_list
            let key = leaver_info[leaver_info.roomId].user_uuid
            switch (leaver_info[leaver_info.roomId].identity) {
                case "owner":
                    obj[leaver_info.roomId].owner = null
                    Queue.removeQ(leaver_info.time)
                    break;
                case "manager":
                case "sysAdmin":
                    delete obj[leaver_info.roomId].managers[key]
                    Queue.removeQ(leaver_info.time)
                    break;
                case "audience":
                    if (leaver_info[leaver_info.roomId].auth.ban_audio_expire_time - Date.now()/1000 <= 0) {
                        delete obj[leaver_info.roomId].audios[key]
                        Queue.removeQ(leaver_info.time)
                    } else {
                        delete obj[leaver_info.roomId].generals[key]
                        Queue.removeQ(leaver_info.time)
                    }
                    break;
                default:
                    return;
            }
            let user_list = {}
            for (let i in obj) {
                user_list[i] = {
                    ...obj[i].us,
                    ...obj[i].owner,
                    ...obj[i].managers,
                    ...obj[i].audios,
                    ...obj[i].generals
                }
            }
            state.room_user_show_list = Object.assign({}, state.room_user_show_list, user_list)
            console.log("减人",leaver_info[leaver_info.roomId].user_name)
            state.last_status = false
        },
        ROOM_INFO_LIST (state, roomInfo) {
            let room_info_list = {}
            room_info_list[roomInfo.roomId] = roomInfo[roomInfo.roomId]
            state.room_info_list = Object.assign({}, state.room_info_list, room_info_list)
        },
        ROOM_USER_UPDATE (state, update_info) {
            let user_list = state.room_user_show_list
            if (user_list[update_info.roomId][update_info.user_uuid[0]]) {
                update_info.ban_text_expire_time == undefined ? user_list[update_info.roomId][update_info.user_uuid[0]].auth.ban_audio_expire_time = update_info.ban_audio_expire_time
                    : user_list[update_info.roomId][update_info.user_uuid[0]].auth.ban_text_expire_time = update_info.ban_text_expire_time
            }
            if (update_info.user_uuid[0] == state.room_info_list[update_info.roomId].user_info.user_uuid) {
                let room_info_list = state.room_info_list
                update_info.ban_text_expire_time == undefined ? room_info_list[update_info.roomId].user_info.auth.ban_audio_expire_time = update_info.ban_audio_expire_time
                    : room_info_list[update_info.roomId].user_info.auth.ban_text_expire_time = update_info.ban_text_expire_time
                state.room_info_list = Object.assign({}, state.room_info_list, room_info_list)
            }
            state.room_user_show_list = Object.assign({}, state.room_user_show_list, user_list)
        },
        BAN_INFO (state, ban_info) {
            let user_list = state.room_info_list
            user_list[ban_info.roomId].room_info.ban_text = ban_info.banText
            user_list[ban_info.roomId].room_info.ban_audio = ban_info.banAudio
            state.room_info_list = Object.assign({}, state.room_info_list, user_list)
        },
        CHAT_RECODE_LIST (state, chat_record) {
            let chat_list = state.chat_record_list
            if (chat_list[chat_record.roomId] == undefined) {
                chat_list[chat_record.roomId] = {}
            }
            chat_list[chat_record.roomId][chat_record.msgId] = chat_record[chat_record.msgId]
            state.chat_record_list = Object.assign({}, state.chat_record_list, chat_list)
        },
        CHAT_RECODE_LIST_UPDATE (state, recall_chat_recode) {
            let chat_list = state.chat_record_list
            let id = "msg_" + recall_chat_recode.msgId
            delete chat_list[recall_chat_recode.roomId][id]
            state.chat_record_list = Object.assign({}, state.chat_record_list, chat_list)
        },
        REPORT_TYPE (state, report_type) {
            state.report_type = report_type
        },
        FAIL_MSG_ID (state, report_fail_msg_id) {
            state.report_fail_msg_id = report_fail_msg_id
        },
        RELATED_NEWS_COUNTS (state, status) {
            status ? state.related_news_counts = 0 : state.related_news_counts ++
        },
        UPDATE_USER_OWN (state, updateInfo) {
            let user_list = state.room_user_show_list
            user_list[updateInfo.room_info.room_id][updateInfo.user_info.user_uuid].mikeStatus = true
            state.room_user_show_list = Object.assign({}, state.room_user_show_list, user_list)
        },
        ROOM_ID (state,room_id) {
            state.room_id = room_id
        },
        JOIN_STATUS (state, join_status) {
            state.join_status = join_status
        }
    },
    actions:{
    }
}
