<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="transparent">
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav v-if="$store.state.login.auth"  class="ml-auto">
        <b-nav-item-dropdown left>
          <template #button-content>
            <b-avatar src="/images/avatar/avatar12.png"
                  class="mr-2"
                ></b-avatar>
            <em>{{ $store.state.login.user.name }}</em>
          </template>
          <b-dropdown-item href="/#/certification">实名认证</b-dropdown-item>
          <b-dropdown-item @click="logout">退出</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

       <b-navbar-nav v-else class="ml-auto">
        <b-nav-item href="/#/auth/login">登录</b-nav-item>
      </b-navbar-nav> 
    </b-collapse>
    </b-navbar>
  </div>
</template>

<script>

export default {
  name: 'TheEntry',
  data() {
    return {
    }
  },
  methods: {
    logout(){
      this.$API.logout({}).then(response => {
        let res = response.data;
        if (res.status === 1){
          this.$store.dispatch('logout');
          this.$router.push('/auth/login')
        } else {
          this.$router.go(0)
        }
        //  退出登录操作会改变session
        //  commonRequest.init_csrf_token(this);
        //  window.location.reload()
      }).catch(error => {
            Promise.reject(error)
      })
    }
  }
}
</script>

<style scoped>
#nav-collapse {
    display: flex!important;
    flex-basis: auto;
}
</style>