<template>
<div class="header" @keyup.esc="closeStatus" tabindex="-1">
    <div class="headerBar">
        <div class="headerBar_con">
            <img class="headerBar_logo" @click.stop="setTab" src="@/assets/images/officialWebsite/navbar_logo.png" alt="传奇云游戏">
            <div class="headerBar_tips">
                <i v-if="user_msg_count" class="msgRed_dot"></i>
                <img src="@/assets/images/officialWebsite/legend_header_tips.png" @click="showMenu">
            </div>
            <ul class="headerBar_nav">
                <!-- 导航 -->
                <li v-for="(menu,index) in menus" :class="{active: index == activeIndex}" :key="index" @click="setTab(index)">{{menu.text}}</li>
                <!-- 登录与未登录样式 -->
                <li v-if="$store.state.login.auth" class="headerBar_msg">
                    <span @click="msgBellStatus">消息</span>
                    <i v-if="user_msg_count" class="msgRed_dot"></i>
                    <!-- 消息提醒列表 -->
                    <ul v-show="msgBellShow" class="msgBell_list">
                        <li v-if="!user_msg_count" class="msgBell_Default">暂无任何消息</li>
                        <li v-else @click.stop="onFeedback">意见反馈 <span class="msgCount_dot">{{user_msg_count}}</span></li>
                    </ul>
                </li>
                <li @click="setTab(7)" v-if="!$store.state.login.auth" :class="{active: 7 == activeIndex}">登录</li>
                <li @click="userStatus" v-else class="headerBar_user">
                    <img class="headerBar_avatar" :src="$store.state.login.user.avatar" alt="">
                    {{ $store.state.login.user.name }}
                    <!-- {{ $store.state.login.user.name }} <i v-if="!loginShow" class="el-icon-arrow-down"></i><i v-if="loginShow" class="el-icon-arrow-up"></i> -->
                    <ul v-show="loginShow" class="headerBar_login">
                        <li @click.stop="onUserCenter"><i class="i-header-center"></i>个人中心</li>
                        <li @click.stop="onDialog"><i class="i-header-user"></i>实名认证</li>
                        <li @click.stop="setTab(6)"><i class="i-header-out"></i>退出登录</li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
    <div class="shadeMenu" @click="tipsShow = false" :style="`display: ${tipsShow? 'unset':'none'};`"></div>
    <div ref="navMenu" :class="['navMenu', tipsShow !== '' ? tipsShow ? 'showNavMenu' :'hideNavMenu' : '']">
        <ul v-show="navListChild == 1">
            <li v-for="(menu,index) in menus"
                :key="index" v-bind:id="menu.id"
                :class="{active: index == activeIndex}"
                @click="setTab(index)">
                <a>
                    <i :class="['i-menu-left','i-menu-'+icons[index],{active: index == activeIndex}]"></i>
                    {{ menu.text }}
                </a>
            </li>
            <li v-if="$store.state.login.auth">
                <a class="msgMin_btn" @click="setMsgList">
                    <i class="i-menu-left i-menu-msg"></i>
                    消息
                    <i v-if="user_msg_count" class="msgRed_dot"></i>
                    <i class="i-triangle-right i-triangle"></i>
                </a>
            </li>
            <li v-if="!$store.state.login.auth"  @click="setTab(7)"  :class="{active: activeIndex == 7}">
                <a><i :class="['i-menu-left','i-menu-user',{active: activeIndex == 7}]"></i>登录</a></li>
            <li v-else  @click="()=>navListChild = 2 " class="navMenu_user">

                <a>
                    <img class="menuUser_img" :src="$store.state.login.user.avatar" @click="showMenu">
                    <!-- <i class="i-menu-left i-menu-user"></i> -->
                    <!--<b-avatar src="/images/avatar/avatar12.png" class="mr-2"></b-avatar> {{ $store.state.login.user.name }} <i v-if="!loginShow" class="el-icon-arrow-down"></i><i v-if="loginShow" class="el-icon-arrow-up"></i>-->
                    {{ $store.state.login.user.name }} <i class="i-triangle-right i-triangle"></i>
                </a>
                <!-- 后续数据渲染，改为变量长度*35 -->

            </li>
        </ul>
        <!-- 用户认证个人中心列表 -->
        <ul v-show="navListChild == 2" class="childMenu">
            <li @click="()=> navListChild = 1"><i class="i-triangle-left i-triangle"></i><a>{{ $store.state.login.user.name }}</a></li>
            <li @click.stop="onDialog"><a>实名认证</a></li>
            <li @click.stop="onUserCenter"><a>个人中心</a></li>
            <li @click.stop="setTab(6)"><a>退出登录</a></li>
        </ul>
        <!-- 消息列表 -->
        <ul v-show="navListChild == 3" class="childMenu">
            <li @click="()=> navListChild = 1"><i class="i-triangle-left i-triangle"></i><a>消息</a></li>
            <li @click.stop="onFeedback"><a> <span class="msgMenu_list">意见反馈</span> <span class="msgCount_dot">{{user_msg_count}}</span></a></li>
        </ul>
    </div>
    <!-- 认证弹出框 -->
    <el-dialog id="userDialog" :visible="watchFlowShow" :modal-append-to-body="false" width="550px" center>
        <img src="@/assets/images/officialWebsite/icon-close.png" class="close-img" @click="closeDialog">
        <p class="title-p"><img src="@/assets/images/officialWebsite/icon-check.png">实名验证</p>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <el-form-item prop="card_name">
                <el-input v-model.trim="ruleForm.card_name" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item prop="card_num">
                <el-input v-model.trim="ruleForm.card_num" placeholder="请输入身份证号" class="pt-2"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="warning" @click="onSubmit('ruleForm')">提交</el-button>
            </el-form-item>
        </el-form>
        <p>
            根据<span class="color-warn">《国家新闻出版署关于防止未成年人沉迷网络游戏的通知》</span>规定，您必须完成实名认证登记才能进入游戏。我们不会泄露您的认证信息或将其用于其他用途，如果您的身份证号码是<span class="color-red">未成年</span>人，您的部分功能将被<span class="color-red">限制</span>。
        </p>
    </el-dialog>
    <!-- 认证弹出框 -->
</div>
</template>

<script>

import Helper from "@/utils/routeStatus";

export default {
    name: "Header",
    props: {
        activeIndex: {
            type: Number,
        }
    },
    data() {
        let cardName = (rule, value, callback) => {
            if (!value) {
                callback(new Error('姓名不能为空'));
            } else if (/[~`!@#$%\^&*()+=\-\[\]\\';,/{}|\\":<>\?]/g.test(value)) {
                callback(new Error('不能含有特殊字符'));
            } else {
                callback();
            }
        };
        let cardNum = (rule, value, callback) => {
            if (!value) {
                callback(new Error('身份证号不能为空'));
            } else if (!/(^\d{15}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
                callback(new Error('身份证号格式有误'));
            } else {
                callback();
            }
        };
        return {
            ruleForm: {
                card_name: '',
                card_num: ''
            },
            rules: {
                card_name: [{
                    validator: cardName,
                    trigger: 'change'
                }, ],
                card_num: [{
                    validator: cardNum,
                    trigger: 'blur'
                }, ],
            },
            icons:['home','news','join'],
            loginShow: false,
            msgBellShow:false,
            tipsShow: '',
            active: "active",
            menus: [{
                    text: '首页'
                },
                {
                    text: '新闻资讯'
                },
                {
                    text: '加入我们'
                }
            ],
            navListChild: 1,            //小屏导航，1首页 2：用户详情 3 消息列表
            msgListShow: false          //控制小屏新消息显示
        }
    },
    mounted() {
        // 解决登录信息的下拉框点击空白处消失的问题
        let that = this
        document.addEventListener('click', (e) => {
            if (!that.$el.contains(e.target)){
                this.loginShow = false;
                this.msgBellShow = false;
            }
        })
        // ---------------------
    },
    computed: {
        watchFlowShow: function () {
            let flowShowState = this.$store.state.certification.flowShow;
            if (flowShowState) this.request_user_card_info();
            return flowShowState;
        },
        // 用户消息
        user_msg_count: function () {
            return this.$store.state.userCenter.feedbackPage;
        },
    },
    watch: {},
    methods: {
        onUserCenter(){

            if(this.$route.name != 'UserCenter'){
                this.$router.push({
                    name: 'UserCenter'
                });
                this.tipsShow = !this.tipsShow;
            }
        },
        onFeedback(){
            let minMaxBool = document.defaultView.getComputedStyle(document.querySelector('.headerBar_tips'), null).display;
                minMaxBool = minMaxBool == 'block'? true : false;

            if(this.$route.name != 'UserCenter'){
                  // 其它页页，跳至意见反馈列表
                this.$router.push({name: 'UserCenter',params:{status:true,size:minMaxBool}});
            }else{
                // 在个人中心页，跳至意见反馈列表
                Helper.$emit('routeStatus',true,minMaxBool)
            }

            if(minMaxBool){
                this.tipsShow = !this.tipsShow;
            }
        },
        closeDialog() {
            this.$store.dispatch('showDialog', false);
        },
        onDialog() {
            this.$store.dispatch('showDialog', true);
        },
        onSubmit(evt) {
            this.$refs[evt].validate((valid) => {
                if (valid) {
                    this.$API.submitUserAuth(this.ruleForm)
                        .then((response) => {
                            let res = response.data;
                            if (res.status == 1) {
                                this.$store.state.login.user.card_num = this.ruleForm.card_num;
                                this.$store.commit('MESSAGE_TYPE', true)
                                this.$store.commit('MESSAGE_TEXT', '认证成功')
                                this.closeDialog()
                            }
                        })
                }
            });
        },
        request_user_card_info() {
            this.$API.getUserInfo({})
                .then((response) => {
                    let res = response.data;
                    if (res.status == 1) {
                        this.ruleForm.card_name = res.data.card_name;
                        this.ruleForm.card_num = res.data.card_num;
                    }
                })
        },
        logout() {
            this.$API.logout({}).then(response => {
                let res = response.data;
                if (res.status === 1) {
                    this.$store.dispatch('logout');
                    if (this.$router.currentRoute.meta.requiresAuth) {
                        this.$router.push({
                            name: 'index'
                        });
                    }
                }
            });
        },
        closeStatus() {
            this.loginShow = false
        },
        userStatus() {
            this.msgBellShow = false;
            this.loginShow = !this.loginShow
        },
        msgBellStatus() {
            this.loginShow = false;
            this.msgBellShow = !this.msgBellShow
        },
        setTab(index) {
            switch (index) {
                case 1:
                    this.$router.push({
                        name: 'NewsInfo'
                    });
                    break;
                case 2:
                    this.$router.push({
                        name: 'RecruitInfo'
                    });
                    break;
                case 6:
                    this.logout()
                    this.tipsShow = false
                    break;
                case 7:
                    this.$router.replace({
                        name:"Login",
                        query: {redirect: this.$router.currentRoute.fullPath}
                    })
                    break;
                default:
                    this.$router.push({
                        name: 'index'
                    })
            }
               this.navListChild = 1;
        },
        showMenu() {
            this.tipsShow = !this.tipsShow;
        },
        setMsgList(){
            // 小屏信息处理
            if(!this.user_msg_count){
                this.$store.commit('MESSAGE_TEXT', '暂无最新消息！');
            }else{
                 this.msgListShow = !this.msgListShow;
                 this.navListChild = 3;
            }
        }
    }
}
</script>

<style lang="scss" scoped>

$triangleActiveRight: transparent transparent transparent #D69D4D;              //三角选中样式
$triangleActiveLeft:  transparent #D69D4D transparent transparent #D69D4D;    //三角选中样式
$triangleColorRight:  transparent transparent transparent #FFFAF4;   //朝右三角
$triangleColorLeft:   transparent #FFFAF4 transparent transparent ;  //朝左三角
/deep/ .el-input__inner:focus {
    border-color: #D69D4D;
    box-shadow: none;
}

button:focus {
    outline: none;
}

.color-warn {
    color: #D69D4D;
    font-weight: 500;
}

.color-red {
    color: #E02020;
    font-weight: 500;
}

.close-img {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.title-p {
    font-size: 16px;
    font-weight: 500;
    color: #888888;
}

.el-button--warning {
    width: 100%;
    height: 56px;
    color: #fff;
    background: #D69D4D;
    margin: 20px 0;
}

/deep/.el-dialog--center .el-dialog__body {
    padding: 25px 75px 30px;
}

/deep/.el-dialog__headerbtn {
    display: none;
}

/deep/.el-input__inner {
    height: 56px;
    font-size: 16px;
}

/*屏幕大于1200px*/
@media screen and (min-width: 1200px) {
    .headerBar_con {
        width: 1200px;
        margin: 0 auto;
    }

    .headerBar_tips,
    .navMenu {
        display: none;
    }
}
/*屏幕小于1200px大于800px*/
@media screen and (max-width: 1200px) and (min-width: 800px) {
    .headerBar_con {
        width: 100%;
    }

    .headerBar_tips,
    .navMenu {
        display: none;
    }
}
/*屏幕小于800px大于375px*/
@media screen and (max-width: 800px) and (min-width: 500px) {
    .headerBar_nav {
        display: none;
    }

    .headerBar_con ,
    .header .navMenu ul .navMenu_user .navMenu_login[data-v-a21da308]{
        width: 100%;
    }
    .header .navMenu > ul li:hover {
        .i-triangle-right{
            border-color: $triangleColorRight !important;
        }
        .msgMin_btn:hover{
            color: #FFFAF4 !important;
        }
    }
}
@media screen and (max-width: 500px) and (min-width: 375px) {
    .headerBar_nav {
        display: none;
    }

    #userDialog /deep/ .el-dialog.el-dialog--center{
            width: 100% !important;
    }

    .headerBar_con ,
    .header .navMenu ul .navMenu_user .navMenu_login[data-v-a21da308]{
        width: 100%;
    }
    /deep/ .el-dialog--center .el-dialog__body{
        padding: 15px 45px 20px;
    }
    .header .navMenu > ul li:hover {
        .i-triangle-right{
            border-color: $triangleColorRight !important;
        }
        .msgMin_btn:hover{
            color: #FFFAF4 !important;
        }
    }
}
/*屏幕小于375px*/
@media screen and (max-width: 374px) {
    .headerBar_nav {
        display: none;
    }

    .headerBar_logo {
        margin: 18px 0 0 5px !important;
    }

    .headerBar_tips {
        margin: 21px 5px 0 0 !important;
    }

    #userDialog /deep/ .el-dialog.el-dialog--center{
        width: 100% !important;
    }

    .headerBar_con ,
    .header .navMenu ul .navMenu_user .navMenu_login[data-v-a21da308]{
        width: 100%;
    }
    /deep/ .el-dialog--center .el-dialog__body{
        padding: 15px 45px 20px;
    }

    .header .navMenu .childMenu {
        .msgCount_dot{
            margin-right: 0px !important;
        }
    }
    .header .navMenu > ul li:hover {
        .i-triangle-right{
            border-color: $triangleColorRight !important;
        }
        .msgMin_btn:hover{
            color: #FFFAF4 !important;
        }
    }
}

ul,
li,
a {
    margin: 0;
    padding: 0;
}

.header {
    position: sticky;
    top: 0;
    z-index: 100;
    .headerBar {
        width: 100%;
        background: #2F333D;
        .headerBar_con {
            height: 80px;
            .headerBar_logo {
                cursor:pointer;
                float: left;
                width: 216px;
                height: 40px;
                margin: 18px 0 0 40px;
            }

            .headerBar_tips,
            .headerBar_nav {
                float: right;
            }

            .headerBar_tips {
                width: 40px;
                height: 40px;
                position: relative;
                margin: 20px 40px 0 0;
                .msgRed_dot{
                    position: absolute;
                    top: 0px;
                    right: 0px;
                }
                img {
                    width: 100%;
                }
            }

            .headerBar_nav {
                height: 42px;
                margin: 18px 40px 0 0;
                >li {
                    float: left;
                    height: 42px;
                    color: #FFFAF4;
                    font-size: 18px;
                    line-height: 42px;
                    margin-right: 24px;
                }
                .headerBar_msg{
                    position: relative;
                    .msgRed_dot{
                        position: absolute;
                        top: 6px;
                    }
                    .msgBell_list{
                        width: 160px;
                        position: absolute;
                        left: 50%;
                        top: 62px;
                        padding: 10px 20px;
                        color: #2F333D;
                        font-size: 14px;
                        border-radius: 5px;
                        margin-left: -80px;
                        background-color: #fff;
                        box-shadow: 0px 2px 8px 0px rgba(213, 165, 97, 0.3);
                        .msgBell_Default{
                            text-align: center;
                            padding: 20px 0;
                        }
                        .msgCount_dot{
                            height: 16px;
                            border-radius: 9999rem;
                            color: #fff;
                            padding: 0 5px;
                            margin-top: 12px;
                            font-size: 12px;
                            font-weight: 400;
                            display: inline-block;
                            background-color: #ff6a6a;
                            text-align: center;
                            line-height: 16px;
                            float:right;
                        }
                    }
                    .msgBell_list::before{
                        display:block;
                        content:'';
                        border-width:6px 8px 6px 8px;
                        border-style:solid;
                        border-color:transparent transparent #fffefb transparent;

                        /* 定位 */
                        position:absolute;
                        left:50%;
                        margin-left: -8px;
                        top:-11px;
                    }
                }
                .headerBar_user {
                    position: relative;
                    // 头像-个人中心
                    // -意见反馈 退出登录 实名认证
                    .headerBar_avatar{
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        border: 1px solid #FAD8A1;
                    }
                    .headerBar_login {
                        position: absolute;
                        left: -64px;
                        top: 62px;
                        width: 160px;
                        padding: 10px 0;
                        background: #fff;
                        border-radius: 5px;
                        box-shadow: 0px 2px 8px 0px rgba(213, 165, 97, 0.3);
                        li {
                            margin: 0 0 5px 20px;
                            width: 120px;
                            height: 45px;
                            font-size: 16px;
                            line-height: 45px;
                            font-weight: bold;
                            color: #2F333D;
                            letter-spacing: 2px;
                            border-bottom: 1px solid #d0d0d0;
                            >i{
                                width: 24px;
                                height: 24px;
                                display: block;
                                float: left;
                                margin-top: 10px;
                                background-size: cover;
                            }
                            .i-header-user {
                                background-image: url('../../assets/images/officialWebsite/i-header-user.png');
                            }
                            .i-header-center {
                                background-image: url('../../assets/images/officialWebsite/i-header-center.png');
                            }
                            .i-header-out {
                                background-image: url('../../assets/images/officialWebsite/i-header-out.png');
                            }
                        }
                        li:last-child{
                            border-bottom: none;
                        }
                        li:hover {
                            cursor: pointer;
                            color: #D69D4D;
                        }
                        i{
                            font-weight: bold;
                            color: #D69D4D;
                            margin-right: 10px;
                        }

                    }
                    .headerBar_login::before{
                        display:block;
                        content:'';
                        border-width:6px 8px 6px 8px;
                        border-style:solid;
                        border-color:transparent transparent #fffefb transparent;

                        /* 定位 */
                        position:absolute;
                        left:50%;
                        margin-left: -8px;
                        top:-11px;
                    }
                }

                li:hover {
                    cursor: pointer;
                    color: #D69D4D;
                }

                .active {
                    color: #D69D4D;
                    border-bottom: 1px solid #D69D4D;
                }
            }
        }
    }
    //遮罩层
    .shadeMenu{
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 5;
        background: rgba(0, 0, 0, 0.6);
    }
    .navMenu {
        width: 70%;
        height: 100%;
        position: fixed;
        top: 80px;
        right: -80%;
        z-index: 10;
        padding:0 32px;
        overflow-y: auto;
        background: #2F333D;
        color: #FFFAF4;
        >ul {
            >li {
                position: relative;
                display: flex;
                align-items: center;
                flex-direction:column;
                >a {
                    width: 100%;
                    padding: 8px 0;
                    font-size: 16px;
                    text-decoration: none;
                    .i-menu-left{
                        display: inline-block;
                        float: left;
                        width: 16px;
                        height: 16px;
                        margin-top: 5px;
                        margin-right: 10px;
                        background-size: cover !important;
                    }
                    .menuUser_img{
                        float: left;
                        width: 24px;
                        height: 24px;
                        margin: 2px 7px 0 -4px;
                    }
                    .i-menu-home{ background: url("../../assets/images/home/i-menu-home.png") no-repeat left top; }
                    .i-menu-news{ background: url("../../assets/images/home/i-menu-news.png") no-repeat left top; }
                    .i-menu-join{ background: url("../../assets/images/home/i-menu-join.png") no-repeat left top; }
                    .i-menu-msg{ background: url("../../assets/images/home/i-menu-msg.png") no-repeat left top; }
                    .i-menu-user{ background: url("../../assets/images/home/i-menu-user.png") no-repeat left top; }
                    .i-menu-home.active{ background: url("../../assets/images/home/i-menu-home-active.png") no-repeat left top; }
                    .i-menu-news.active{ background: url("../../assets/images/home/i-menu-news-active.png") no-repeat left top; }
                    .i-menu-join.active{background: url("../../assets/images/home/i-menu-join-active.png") no-repeat left top; }
                    .i-menu-msg.active{ background: url("../../assets/images/home/i-menu-msg-active.png") no-repeat left top; }
                    .i-menu-user.active{ background: url("../../assets/images/home/i-menu-user-active.png") no-repeat left top; }
                }
            }

            li:hover {
                cursor: pointer;
                color: #D69D4D;
                .msgMin_btn{
                    color: #D69D4D;
                }
                .i-triangle-right{
                    border-color: $triangleActiveRight;
                }
                .i-triangle-left{
                    border-color: $triangleActiveLeft;
                }
            }
            .active {
                color: #D69D4D;
                .i-triangle-right{
                    border-color: $triangleActiveRight;
                }
                .i-triangle-left{
                    border-color: $triangleActiveLeft;
                }
            }
            .i-triangle{
                position: absolute;
                top: 15px;
                border-style: solid;
                border-width: 8px;
            }
            .i-triangle-right{
                right: 0px;
                border-color: $triangleColorRight;
            }
            .i-triangle-left{
                left: 0;
                top: 14px;
                margin-left: -16px;
                border-color: $triangleColorLeft;
            }

        }
        // 导航，二级菜单样式
        .childMenu{
            li>a{
                padding-left: 20px;
            }
            :not(:first-child){
                a{
                    padding-left: 30px;
                    font-size: 14px;
                }
            }
            .msgMenu_list{
                width: 73%;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
            }
            .msgCount_dot{
                height: 16px;
                border-radius: 9999rem;
                color: #fff;
                padding: 0 5px;
                margin-top: 6px;
                margin-right: 15px;
                display: inline-block;
                background-color: #ff6a6a;
                text-align: center;
                line-height: 16px;
                font-size: 12px;
                float:right;
            }

        }
        .msgRed_dot{
            position:absolute;
            top: 12px;
            display: inline-block;
        }
    }
    .showNavMenu{
        -webkit-animation: showIn .5s 0s ease-in-out both;
        -moz-animation: showIn .5s 0s ease-in-out both;
        -ms-animation: showIn .5s 0s ease-in-out both;
        animation: showIn .5s 0s ease-in-out both;
    }

    .hideNavMenu{
        -webkit-animation: fadeOut .2s 0s ease-in-out both;
        -moz-animation: fadeOut .2s 0s ease-in-out both;
        -ms-animation: fadeOut .2s 0s ease-in-out both;
        animation: fadeOut .2s 0s ease-in-out both;
    }
}

/*
* 导航栏动画
 */
@-webkit-keyframes showIn {
    0% {
        opacity: 0;
        filter: alpha(opacity=0);
    }

    100% {
        opacity: 1;
        filter: alpha(opacity=100);
        right: 0px;
    }

}
@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
        filter: alpha(opacity=100);
        right: 0px;
    }

    100% {
        opacity: 0;
        filter: alpha(opacity=0);
        right:-80%
    }

}

.msgRed_dot{
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: red;
}

/deep/ #msgToast_list{
    >div{
        width: 100% !important;
    }
}

</style>

