export default {
    /**
     * 判断是不是IE浏览器
     * @returns {boolean}
     */
    isIE() {
        if (navigator.userAgent.toLowerCase().indexOf('trident')>-1) {
            return true;
        }
        return false;//不是ie浏览器
    },

    /**
     * 判断是不是手机
     * @returns {boolean}
     */
    isMobile() {
        if (!!navigator.userAgent.match(/AppleWebKit.*Mobile.*/)) {
            return true; //手机
        }

        if((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
            return true; //手机
        }
        return false;
    },

    /**
     * 创建验证码
     * @param num
     * @param letters
     * @returns {{tpl: string, captcha: string}}
     */
    createCaptcha(num = 6, letters = 'QWERTYUIOPLKJHGFDSAZXCVBNM1234567890') {
        let tpl = ''
        let captcha = []

        try {
            captcha = [...Array(num)].map(() => letters[Math.floor(Math.random() * letters.length)])
        } catch (e) { }

        captcha.forEach(item => {
            tpl += `<span class="flex1 hcenter">${item}</span>`
        })

        captcha = captcha.join('')

        return {
            tpl,
            captcha
        }
    },

    /**
     * 判断是不是微信内置浏览器
     * @returns {boolean}
     */
    isWeiXin() {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true;
        } else {
            return false;
        }
    },
    /**
     * 判断是不是iphone,iPad
     * @returns {boolean}
     */
    isIphone() {
        var ua = window.navigator.userAgent;
        if ((ua.indexOf('iPhone')>-1 || ua.indexOf('iPad')>-1)) {
            return true;
        } else {
            return false;
        }
    },
    /**
     * 判断是不是iphone,iPad的safari
     * @returns {boolean}
     */
    isIosSafari() {
        var ua = window.navigator.userAgent;
        if ((ua.indexOf('iPhone')>-1 || ua.indexOf('iPad')>-1) && (/Safari/.test(ua) && !/Chrome/.test(ua))) {
            return true;
        } else {
            return false;
        }
    },
    /**
     * 判断是不是Mac的safari
     * @returns {boolean}
     */
    isMac() {
        var ua = window.navigator.userAgent;
        if ((/macintosh|mac os x/i.test(ua)) && (/Safari/.test(ua) && !/Chrome/.test(ua))) {
            return true;
        } else {
            return false;
        }
    },
    /**
     * 判断是不是浏览器是否禁用cookie
     * @returns {boolean}
     */
    isCookie () {
        return window.navigator.cookieEnabled ? true : false;
    },

    // 使用a标签模拟window.open  避免Safari拦截
    windowOpen(url) {
        if (url) {
            var a = document.createElement('a');
            a.setAttribute('href', url);
            a.setAttribute('target', '_blank');
            var hid = Date.now();
            a.setAttribute('id', hid);
            // 防止反复添加
            if(!document.getElementById(hid)) {
                document.body.appendChild(a);
            }
            a.click();
        }
    }
}