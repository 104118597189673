<template>
    <div class="news">
        <img :src="recruitDate.cover" alt="传奇云游戏" :titlle="recruitDate.title">
        <div class="news-content">
            <h2>{{recruitDate.title}}</h2>
            <i>{{recruitDate.date}}</i>
            <p @click.stop="seeMore" :class="{active: show == true}">[摘要] {{recruitDate.summary}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "News",
        props: {
            recruitDate: {
                type: Object,
                required: true
            }
        },
        data () {
            return {
                show: true
            }
        },
        methods: {
            seeMore () {
                this.show = !this.show
            }
        }
    }
</script>

<style scoped lang="scss">
    i {
        font-style: normal;
    }
    @media screen and (min-width: 1200px) {
        .news {
            width: 1120px;
            /*height: 240px;*/
            margin: 0 auto;
            img {
                width: 400px;
            }
            .news-content {
                width: 720px;
                padding: 0 56px 0 40px;
            }
        }
    }

    @media screen and (max-width: 1200px) and (min-width: 800px) {
        .news {
            img {
                width: 400px;
            }
            .news-content {
                padding: 0 10px 0 20px;
            }
        }
    }

    @media screen and (max-width: 800px) and (min-width: 600px) {
        .news {
            align-items: center;
            img {
                width: 300px;
                height: 240px;
            }
            .news-content {
                padding: 0 10px 0 20px;
            }
        }
    }

    @media screen and (max-width: 600px) and (min-width: 400px) {
        .news {
            flex-direction: column;
            align-items: center;
            img {
                width: 360px;
                height: 240px;
            }
            .news-content {
                padding: 0 10px 0 20px;
            }
        }
    }

    @media screen and (max-width: 400px) {
        .news {
            flex-direction: column;
            img {
                width: 100%;
                height: 162px;
            }
            .news-content {
                padding: 0 10px 0 20px;
            }
        }
    }
    div,img,h2,i,p {margin: 0;padding: 0}
    .news {
        box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(0,0,0,0);
        cursor: pointer;
        display: flex;
        .news-content {
            width: 100%;
            h2 {
                font-size: 24px;
                font-weight: 400;
                line-height: 33px;
                margin-top: 32px;
                color: #2F333D;
            }
            i {
                display: block;
                background-image: url("../../assets/images/officialWebsite/news/news-icon.png");
                background-repeat: no-repeat;
                background-size: 18px 18px;
                background-position: left center;
                color: #ADADB0;
                font-size: 16px;
                height: 22px;
                line-height: 22px;
                padding-left: 26px;
                margin-top: 16px;
            }
            p {
                color: #888888;
                font-size: 18px;
                line-height: 30px;
                margin: 24px 0 10px 0;
                /*position: relative;*/
                /*span {*/
                /*    background: #FFFFFF;*/
                /*    position: absolute;*/
                /*    right: 5px;bottom: 0;*/
                /*    color: #D5A561;*/
                /*}*/
            }
            .active {
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }
    .news:hover {
        .news-content {
            h2 {
                color: #D5A561;
            }
        }
        border: 1px solid #FAD8A1;
    }
</style>