import Vue from 'vue'
import Message from './Message'
import LcgLoading from './LcgLoading'
import TheHeader from "./layouts/TheHeader";
import TheEntry from "./layouts/TheEntry";
import TheFooter from "./layouts/TheFooter";
import NewCloudGameLauncher from './cloudGameLauncher/NewCloudGameLauncher'
import Loading from './cloudGameLauncher/Loading'
import SideComment from './cloudGameLauncher/SideComment'
import Header from './officialWebsite/Header'
import Recruit from './officialWebsite/Recruit'
import News from './officialWebsite/News'
import Footer from './officialWebsite/Footer'
import CloudGameModal from './daoyu/CloudGameModal'
import FeedBack from './FeedBack'
import Joystick from './joystick/index'



const components = {
  Message, LcgLoading, Header, Recruit, Footer, TheHeader, TheEntry, TheFooter, CloudGameModal, NewCloudGameLauncher, Loading, News, FeedBack, SideComment, Joystick
}

for (let key in components) {
  Vue.component(key, components[key])
}
