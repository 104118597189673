import ls from "../../utils/localStorage";

export default{
    state:{
        user: ls.getItem('user') ?? '',
        // 添加 auth 来保存当前用户的登录状态
        auth: ls.getItem('auth') || false,
    },
    mutations:{
        UPDATE_USER(state, user) {
            state.user = user
            ls.setItem('user', user)
        },
        // 添加 UPDATE_AUTH 来更改当前用户的登录状态
        UPDATE_AUTH(state, auth) {
            state.auth = auth
            ls.setItem('auth', auth)
        },
    },
    actions:{
        login({commit}, user) {
            if (user) {
                commit('UPDATE_USER', user)
                // 更新当前用户的登录状态为已登录
                commit('UPDATE_AUTH', true)
            }
        },
        logout({commit}) {
            ls.removeItem('user')
            commit('UPDATE_USER', '')
            commit('UPDATE_AUTH', false)
        },
    }
}