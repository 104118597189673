const neffos = require('neffos.js');
const { detect } = require('detect-browser');
import store from '@/store';
import lcgWsUtil from '@/utils/lcgWsUtil';
import localGameState from '@/utils/localGameState';


export default {
    connectWsServer,
    wsServer,
    stopWs,
    removeQ
}

let heartbeatTimer = null;
let chatWebSocketConnection = null;
let dataLists = [];
let timer= null;

function removeQ(time) {
    let index = dataLists.findIndex(item =>{
        if(item.time == time){
            return true
        }
    })
    dataLists.splice(index,1)
}



/**
 * 链接ws server
 * @param ws_server
 * @param onWsConnected
 * @param onRankChanged
 * @param onWsError
 * @param onWsDisConnected
 * @returns {Promise<void>}
 * onWsConnected,onWsError,onWsDisConnected
 */
async function connectWsServer(ws_server, onWsConnected, onWsDisConnected) {
    try {
        const browser = detect();
        const conn = await neffos.dial(
            ws_server,
            {
                Gaming: {
                    _OnNamespaceConnected:function(nsConn, msg){
                        _onWsConnected(nsConn,msg,onWsConnected)
                    },
                    _OnNamespaceDisconnect: function (nsConn, msg) {
                        _onWsDisConnected(nsConn,msg,onWsDisConnected)
                    },
                    gameChat: function(nsConn, msg) {
                        _onGameChatResult(nsConn, msg)
                    },
                    notify: function(nsConn, msg){
                        _onNotify(nsConn, msg)
                    }
                },
            },
            {
                headers: {
                    "app_id": "cn.dygame.web",
                    "app_key": "VvmvxZuu1pfPYgRqBUslZW0X-D1tiIQgLtFmZQ9iKIY",
                    "browser_version":browser.version,
                    "browser_name": browser.name,
                }
            }
        );
        await conn.connect('Gaming');
    } catch (err) {
        // onWsError(-1,"服务器连接失败，请稍后重试");
    }
}

/**
 * 连接websocket成功后持续发送心跳包
 * @param nsConn
 * @param msg
 * @param onWsConnected
 * @private
 */
function _onWsConnected(nsConn, msg,onWsConnected) {
    chatWebSocketConnection = nsConn;
    heartbeatTimer = setInterval(() => {
        if (nsConn) {
            let session_id = localGameState.getSessionId();
            nsConn.emit('gameChat', lcgWsUtil.wsMessageBody(10086, '', '', session_id));
        }
    }, 30000);
    timer = setInterval(()=>{
        if (dataLists.length != 0) {
            if (store.state.chat.last_status) {
                console.log("上一次操作正常进行...")
            } else {
                console.log(JSON.stringify(dataLists))
                dataLists[0].joiner ? store.commit("ROOM_LIST_ADD",dataLists[0]) : store.commit("ROOM_LIST_DELETE",dataLists[0])
                // dataLists.shift()
            }
        } else {
            console.log("队列已完成...")
        }
    },1000)
    onWsConnected(nsConn);
}

/**
 * 断开websocket
 * @param nsConn
 * @param msg
 * @param onWsDisConnected
 * @private
 */
function _onWsDisConnected(nsConn, msg, onWsDisConnected) {
    if(heartbeatTimer){
        clearInterval(heartbeatTimer);
    }
    clearInterval(timer)
    console.log("WS server:断开链接"+JSON.stringify(msg));
    onWsDisConnected();
}

/**
 * 主要涉及聊天室 相关配置
 * @param nsConn
 * @param msg
 * @private
 */
function _onGameChatResult(nsConn, msg) {
    // 解密
    let body = lcgWsUtil.MyBase64Decode(msg.Body)
    let _resultJSON = JSON.parse(body)
    console.log('gameChat返回信息',_resultJSON)
    _processingErrCode(_resultJSON.err_code,_resultJSON.msg, msg.Room)
    let _type = _resultJSON.data ? _resultJSON.data.type : ''
    let data = _resultJSON.data
    let us = {}
    let owner = {}
    let managers = {}
    let audios = {}
    let generals = {}
    switch (_type) {
        case 5000000:
            if (data.owner) {
                owner[data.owner.user_uuid] = data.owner
            }
            if (data.managers) {
                data.managers.map(item => {
                    if (store.state.login.user.uuid == item.user_uuid) {
                        us[item.user_uuid] = item
                    } else {
                        managers[item.user_uuid] = item
                    }
                })
            }
            if (data.generals) {
                let audiosArr = data.generals.filter(item=>{
                    return item.auth.ban_audio_expire_time - Date.now()/1000 <= 0
                })
                audiosArr.map(item => {
                    if (store.state.login.user.uuid == item.user_uuid) {
                        us[item.user_uuid] = item
                    } else {
                        audios[item.user_uuid] = item
                    }
                })
                let generalsArr = data.generals.filter(item=>{
                    return item.auth.ban_audio_expire_time - Date.now()/1000 > 0
                })
                generalsArr.map(item => {
                    if (store.state.login.user.uuid == item.user_uuid) {
                        us[item.user_uuid] = item
                    } else {
                        generals[item.user_uuid] = item
                    }
                })
            }
            let room_cache_list = {}
            room_cache_list.roomId = msg.Room
            room_cache_list[msg.Room] = {
                us, // 自己
                owner, // 房主
                managers, // 管理员
                audios, // 可语音的观众
                generals // 不可语音的观众
            }
            store.commit('ROOM_CACHE_LIST', room_cache_list)
            break;
        case 5000001:
            let roomInfo = {}
            roomInfo.roomId = msg.Room
            roomInfo[msg.Room] = {
                room_info: data.room_info,
                user_info: data.user_info,
                app_id: data.app_id,
                user_sig: data.user_sig
            }
            store.commit("ROOM_ID", data.audio_room_id)
            store.commit('ROOM_INFO_LIST', roomInfo)
            break;
        case 5000003:
            let ban_info = {
                roomId: msg.Room,
                banAudio: data.ban_audio,
                banText: data.ban_text
            }
            store.commit('BAN_INFO', ban_info)
            break;
        case 5000005:
            let update_info = {
                roomId: msg.Room,
                user_uuid: data.operated_users,
                ban_text_expire_time: data.ban_text_expire_time,
                ban_audio_expire_time: data.ban_audio_expire_time
            }
            store.commit('ROOM_USER_UPDATE', update_info)
            break;
        case 5000006:
            let remove_ban_info = {
                roomId: msg.Room,
                user_uuid: [data.user_uuid],
                ban_audio_expire_time: data.ban_audio_expire_time,
                ban_text_expire_time: data.ban_text_expire_time
            }
            store.commit('ROOM_USER_UPDATE', remove_ban_info)
            break;
        case 5000010:
            let chat_list = store.state.chat.chat_record_list
            if (chat_list[msg.Room].msg_ownMessage) {
                let id = "msg_" + data.msg_id
                chat_list[msg.Room][id] = chat_list[msg.Room].msg_ownMessage
                chat_list[msg.Room][id].msg_id = data.msg_id
                chat_list[msg.Room][id].status = 0
                delete chat_list[msg.Room].msg_ownMessage
            } else {
                let id = "msg_" + data.msg_id
                chat_list[msg.Room][id] = chat_list[msg.Room][store.state.chat.report_fail_msg_id]
                chat_list[msg.Room][id].msg_id = data.msg_id
                chat_list[msg.Room][id].status = 0
                delete chat_list[msg.Room][store.state.chat.report_fail_msg_id]
            }
            store.state.chat.chat_record_list = Object.assign({}, store.state.chat.chat_record_list, chat_list)
            break;
        case 5000011:
            let chat_record = {}
            chat_record.roomId = msg.Room
            chat_record.msgId = "msg_" + data.msg_id
            if (data.at_user_uuids) {
                let arr = data.at_user_uuids.filter((item)=>{
                    return item == store.state.login.user.uuid
                })
                arr.length != 0 ? store.commit('RELATED_NEWS_COUNTS') : ''
            }
            chat_record[chat_record.msgId] = {
                sender: data.sender,
                content: data.content,
                at_user_uuids: data.at_user_uuids ? data.at_user_uuids : [],
                reply_id: data.reply_id ? data.reply_id : -1,
                msg_id: data.msg_id
            }
            store.commit('CHAT_RECODE_LIST', chat_record)
            break;
        case 5000013:
            let recall_chat_recode = {}
            recall_chat_recode.roomId = msg.Room
            recall_chat_recode.msgId = data.msg_id
            store.commit('CHAT_RECODE_LIST_UPDATE', recall_chat_recode);
            break;
        case 5000021:
            let joiner_info = {}
            joiner_info.roomId = msg.Room
            joiner_info.joiner = true
            joiner_info[msg.Room] = data.joiner
            joiner_info.time = Date.now();
            dataLists.push(joiner_info)
            break;
        case 5000031:
            let leaver_info = {}
            leaver_info.roomId = msg.Room
            leaver_info.joiner = false
            leaver_info[msg.Room] = data.leaver
            leaver_info.time = Date.now();
            dataLists.push(leaver_info)
            break;
        case 5000050:
            store.commit('REPORT_TYPE', data.report_types);
            break;
        default:
            break;
    }
}

/**
 * 主要涉及广播通知相关配置
 * @param nsConn
 * @param msg
 * @private
 */
function _onNotify(nsConn, msg) {
    // 解密
    let body = lcgWsUtil.MyBase64Decode(msg.Body)
    let _resultJSON = JSON.parse(body);
    console.log('notify返回信息',_resultJSON)
    _processingErrCode(_resultJSON.err_code,_resultJSON.msg,msg.Room)
    // let _type = _resultJSON.data ? _resultJSON.data.type : ''
    // let data = _resultJSON.data
    // switch (_type) {
    //     case 50001:
    //     default:
    //         break;
    // }
}

function stopWs() {
    if (chatWebSocketConnection) {
        chatWebSocketConnection.conn.close()
    }
}

/**
 * 错误码处理
 * @param _errCode
 * @param _errorMsg
 * @private
 */
function _processingErrCode(_errCode, _errorMsg, roomId) {
    let chat_list = {}
    switch(_errCode){
        case "70101": // ws进房失败
            // 弹出弹窗提示重新进入
            store.commit('JOIN_STATUS',false)
            return;
        case "0":
            return;
        case "70400": // 文字不合规
            store.commit('MESSAGE_TEXT', _errorMsg)
            chat_list = store.state.chat.chat_record_list
            delete chat_list[roomId].msg_ownMessage
            store.state.chat.chat_record_list = Object.assign({}, store.state.chat.chat_record_list, chat_list)
            break;
        case "70401":// 请不要刷屏
        case "70402":// 发送消息太频繁
            store.commit('MESSAGE_TEXT', _errorMsg)
            chat_list = store.state.chat.chat_record_list
            if (chat_list[roomId].msg_ownMessage) {
                let id = "msg_" + Date.now()
                chat_list[roomId][id] = chat_list[roomId].msg_ownMessage
                chat_list[roomId][id].msg_id = id
                chat_list[roomId][id].status = 2
                delete chat_list[roomId].msg_ownMessage
            }
            if (chat_list[roomId][store.state.chat.report_fail_msg_id]) {
                chat_list[roomId][store.state.chat.report_fail_msg_id].status = 2
            }
            store.state.chat.chat_record_list = Object.assign({}, store.state.chat.chat_record_list, chat_list)
            break;
        default:
            store.commit('MESSAGE_TEXT', _errorMsg)
            return;
        // case "1"://请求错误
        // case "70400": // 文字不合规
        // case "70401": // 请不要刷屏
        // case "70402": // 发送消息太频繁
        // case "70401": // 请不要刷屏
    }
}

/**
 * 拼接连接ws需要的链接
 * @param server_url
 * @param session_id
 * @returns {string}
 */
function wsServer(server_url, session_id) {
    let ws_server = 'wss://im.dygame.com.cn:8060/chat?session_id=' + session_id;
    if (server_url) ws_server = server_url + '/chat?session_id=' + session_id;
    return ws_server;
}



