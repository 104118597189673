<template>
<!--叨鱼登录弹框-->
<div>
    <b-modal ref="sdo-modal" id="sdo-login-modal" hide-footer no-close-on-backdrop centered>
        <template #modal-title>
            <h5 class="modal-title h4" id="sdo-login-modal-label" style="font-weight:bold;">请使用盛趣游戏账号登录</h5>
        </template>
        <div class="d-block text-center">
            <iframe id="iframe_sdo_login" style="width: 100%;height: 450px" scrolling="no" allow="autoplay" allowfullscreen="true" allowtransparency="true" class="iframe-login" sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-pointer-lock"
            :src="daoyuLink()" frameborder="0">
            </iframe>
        </div>
    </b-modal>
    <div v-if="phoneShow" class="sdoLogin-phoneFloat" >
        <img src="../../assets/images/game/phone-tips.png" alt="">
        <img src="../../assets/images/game/phone-btn.png" @click="()=>{$bvModal.show('sdo-login-modal');phoneShow=false}">
    </div>
    <div v-if="pcShow" class="sdoLogin-pcFloat">
        <img src="../../assets/images/game/pc-tips1.png" alt="">
        <img src="../../assets/images/game/pc-tips.png" alt="">
        <img src="../../assets/images/game/pc-btn.png" @click="()=>{$bvModal.show('sdo-login-modal');pcShow=false}">
    </div>
</div>
<!-- 登录弹框 -->
</template>

<script>
    import helper from "@/utils/helper";
export default {
    name: "CloudGameModal",
    data() {
        return {
            phoneShow: false,
            pcShow: false
        };
    },
    props: ['appId'],
    methods: {
        showSdoLoginModal() {
            if (helper.isIosSafari() && helper.isCookie()) {
                this.phoneShow = true
            } else if (helper.isMac() && helper.isCookie()) {
                this.pcShow = true
            } else {
                this.$bvModal.show('sdo-login-modal');
            }
        },
        daoyuLink() {
          return 'https://login.sdo.com/sdo/Login/LoginFrameFC.php?pm=2&target=iframe&appId=' + this.appId + '&areaId=1&returnURL=' + this.$store.state.baseUrl + '/daoyu/sdo_login_callback';
        },
    }
}
</script>

<style scoped lang="scss">
    .sdoLogin-phoneFloat {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        z-index: 16;
        background: #2F333D;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img:nth-child(1) {
            height: 60%;
            background-size: cover;
        }
        img:nth-child(2) {
            height: 20%;
            background-size: cover;
            margin-top: 5%;
        }
    }
    .sdoLogin-pcFloat {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        z-index: 16;
        background: rgba(0,0,0,0.5);
        img:nth-child(1) {
            width: 677px;
            height: 270px;
            position: absolute;
            top: 80px; left: 70px;
        }
        img:nth-child(2) {
            width: 584px;
            height: 138px;
            position: absolute;
            top: 50%;left: 50%;
            transform: translate(-50%,-50%);
        }
        img:nth-child(3) {
            width: 278px;
            height: 112px;
            position: absolute;
            top: 70%;left: 50%;
            transform: translate(-50%,-50%);
        }
    }
</style>
