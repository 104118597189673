
export default{
    state:{
        flowShow: false,
    },
    mutations:{
        SHOW_DIALOG(state, status) {
            state.flowShow = status
        }
    },
    actions:{
        showDialog({commit}, type) {
            commit('SHOW_DIALOG', type)
        }
    }
}