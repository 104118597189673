// 跳转回调状态
export default{
    //个人中心
    state:{
        feedbackPage: 0        //意见反馈消息数
    },
    mutations:{
        //意见反馈消息数
        SET_FEEDBACK_PAGE(state,page){
            state.feedbackPage = page;
        }
    },
    actions:{
        setUserMsg({commit}, page) {
            commit('SET_FEEDBACK_PAGE', page)
        }
    }
}
